export const ADVISOR_TEXTFIELDS = [
  // {name: 'protectionInitialFee', label:'Protection Initial Fee*'},
  // {name: 'protectionOngoingFee', label:'Protection Ongoing Fee*'},
  // {name: 'protectionInitial', label:'Protection Initial*'},
  // {name: 'protectionInitialCommission', label:'Protection Initial Commission*'},
  // {name: 'protectionPayawaysReceived', label:'Protection Payaways Received*'},
  // {name: 'protectionAdhocFee', label:'Protection Ad-hoc Fee*'},
  // {name: 'protectionRenewal', label:'Protection Renewal*'},
  // {name: 'protectionRenewalCommission', label:'Protection Renewal Commission*'},
  // {name: 'protectionFundBased', label:'Protection Fund Based*'},
  // {name: 'protectionFundBasedCommission', label:'Protection Fund Based Commission*'},
  // {name: 'protectionFeesAndRetainers', label:'Protection Fees and Retainers*'},
  // {name: 'protectionRetainerClientPaid', label:'Fee/Retainer (Client Paid)*'},
  // {name: 'investmentInitialFee', label:'Investment Initial Fee*'},
  // {name: 'investmentOngoingFee', label:'Investment Ongoing Fee*'},
  // {name: 'investmentInitial', label:'Investment Initial*'},
  // {name: 'investmentInitialCommission', label:'Investment Initial Commission*'},
  // {name: 'investmentPayawaysReceived', label:'Investment Payaways Received*'},
  // {name: 'investmentAdhocFee', label:'Investment Ad-hoc Fee*'},
  // {name: 'investmentRenewal', label:'Investment Renewal*'},
  // {name: 'investmentRenewalCommission', label:'Investment Renewal Commission*'},
  // {name: 'investmentFundBased', label:'Investment Fund Based*'},
  // {name: 'investmentFundBasedCommission', label:'Investment Fund Based Commission*'},
  // {name: 'investmentFeesAndRetainers', label:'Investment Fees and Retainers*'},
  // {name: 'investmentRetainerClientPaid', label:'Fee/Retainer (Client Paid)*'},
  // {name: 'pensionsInitialFee', label:'Pensions Initial Fee*'},
  // {name: 'pensionsOngoingFee', label:'Pensions Ongoing Fee*'},
  // {name: 'pensionsInitial', label:'Pensions Initial*'},
  // {name: 'pensionsInitialCommission', label:'Pensions Initial Commission*'},
  // {name: 'pensionsPayawaysReceived', label:'Pensions Payaways Received*'},
  // {name: 'pensionsAdhocFee', label:'Pensions Ad-hoc Fee*'},
  // {name: 'pensionsRenewal', label:'Pensions Renewal*'},
  // {name: 'pensionsRenewalCommission', label:'Pensions Renewal Commission*'},
  // {name: 'pensionsFundBased', label:'Pensions Fund Based*'},
  // {name: 'pensionsFundBasedCommission', label: 'Pensions Fund Based Commission*'},
  // {name: 'pensionsFeesAndRetainers', label: 'Pensions Fees and Retainers*'},
  // {name:'pensionsRetainerClientPaid', label:'Pensions Fee/Retainer (Client Paid)*'},
  // {name: 'mortgageInitialFee', label:'Mortgage Initial Fee*'},
  // {name: 'mortgageOngoingFee', label:'Mortgage Ongoing Fee*'},
  // {name: 'mortgageInitial', label:'Mortgage Initial*'},
  // {name: 'mortgageInitialCommission', label:'Mortgage Initial Commission*'},
  // {name: 'mortgagePayawaysReceived', label:'Mortgage Payaways Received*'},
  // {name: 'mortgageAdhocFee', label:'Mortgage Ad-hoc Fee*'},
  // {name: 'mortgageRenewal', label:'Mortgage Renewal*'},
  // {name: 'mortgageRenewalCommission', label:'Mortgage Renewal Commission*'},
  // {name: 'mortgageFundBased', label:'Mortgage Fund Based*'},
  // {name: 'mortgageFundBasedCommission', label: 'Mortgage Fund Based Commission*'},
  // {name: 'mortgageFeesAndRetainers', label: 'Mortgage Fees and Retainers*'},
  // {name:'mortgageRetainerClientPaid', label:'Mortgage Fee/Retainer (Client Paid)*'},
  // {name: 'generalInsuranceInitialFee', label:'General Insurance Initial Fee*'},
  // {name: 'generalInsuranceOngoingFee', label:'General Insurance Ongoing Fee*'},
  // {name: 'generalInsuranceInitial', label:'General Insurance Initial*'},
  // {name: 'generalInsuranceInitialCommission', label:'General Insurance Initial Commission*'},
  // {name: 'generalInsurancePayawaysReceived', label:'General Insurance Payaways Received*'},
  // {name: 'generalInsuranceAdhocFee', label:'General Insurance Ad-hoc Fee*'},
  // {name: 'generalInsuranceRenewal', label:'General Insurance Renewal*'},
  // {name: 'generalInsuranceRenewalCommission', label:'General Insurance Renewal Commission*'},
  // {name: 'generalInsuranceFundBased', label:'General Insurance Fund Based*'},
  // {name: 'generalInsuranceFundBasedComission', label: 'General Insurance Fund Based Commission*'},
  // {name: 'generalInsuranceFeesAndRetainers', label: 'General Insurance Fees and Retainers*'},
  // {name:'generalInsuranceFeeRetainerClientPaid', label:'General Insurance Fee/Retainer (Client Paid)*'},
];

export const advisorBannerTextfields = [
  { name: 'protection', label: 'Protection', type: 'banner' },
  { name: 'protectionInitialFee', label: 'Protection Initial Fee*' },
  { name: 'protectionOngoingFee', label: 'Protection Ongoing Fee*' },
  { name: 'protectionInitial', label: 'Protection Initial*' },
  {
    name: 'protectionInitialCommission',
    label: 'Protection Initial Commission*',
  },
  {
    name: 'protectionPayawaysReceived',
    label: 'Protection Payaways Received*',
  },
  { name: 'protectionAdhocFee', label: 'Protection Ad-hoc Fee*' },
  { name: 'protectionRenewal', label: 'Protection Renewal*' },
  {
    name: 'protectionRenewalCommission',
    label: 'Protection Renewal Commission*',
  },
  { name: 'protectionFundBased', label: 'Protection Fund Based*' },
  {
    name: 'protectionFundBasedCommission',
    label: 'Protection Fund Based Commission*',
  },
  {
    name: 'protectionFeesAndRetainers',
    label: 'Protection Fees and Retainers*',
  },
  {
    name: 'protectionFeeRetainerClientPaid',
    label: 'Protection Fee/Retainer (Client Paid)*',
  },
  { name: 'investment', label: 'Investment', type: 'banner' },
  { name: 'investmentInitialFee', label: 'Investment Initial Fee*' },
  { name: 'investmentOngoingFee', label: 'Investment Ongoing Fee*' },
  { name: 'investmentInitial', label: 'Investment Initial*' },
  {
    name: 'investmentInitialCommission',
    label: 'Investment Initial Commission*',
  },
  {
    name: 'investmentPayawaysReceived',
    label: 'Investment Payaways Received*',
  },
  { name: 'investmentAdhocFee', label: 'Investment Ad-hoc Fee*' },
  { name: 'investmentRenewal', label: 'Investment Renewal*' },
  {
    name: 'investmentRenewalCommission',
    label: 'Investment Renewal Commission*',
  },
  { name: 'investmentFundBased', label: 'Investment Fund Based*' },
  {
    name: 'investmentFundBasedCommission',
    label: 'Investment Fund Based Commission*',
  },
  {
    name: 'investmentFeesAndRetainers',
    label: 'Investment Fees and Retainers*',
  },
  {
    name: 'investmentFeeRetainerClientPaid',
    label: 'Investment Fee/Retainer (Client Paid)*',
  },
  { name: 'pensions', label: 'Pensions', type: 'banner' },
  { name: 'pensionsInitialFee', label: 'Pensions Initial Fee*' },
  { name: 'pensionsOngoingFee', label: 'Pensions Ongoing Fee*' },
  { name: 'pensionsInitial', label: 'Pensions Initial*' },
  { name: 'pensionsInitialCommission', label: 'Pensions Initial Commission*' },
  { name: 'pensionsPayawaysReceived', label: 'Pensions Payaways Received*' },
  { name: 'pensionsAdhocFee', label: 'Pensions Ad-hoc Fee*' },
  { name: 'pensionsRenewal', label: 'Pensions Renewal*' },
  { name: 'pensionsRenewalCommission', label: 'Pensions Renewal Commission*' },
  { name: 'pensionsFundBased', label: 'Pensions Fund Based*' },
  {
    name: 'pensionsFundBasedCommission',
    label: 'Pensions Fund Based Commission*',
  },
  { name: 'pensionsFeesAndRetainers', label: 'Pensions Fees and Retainers*' },
  {
    name: 'pensionsFeeRetainerClientPaid',
    label: 'Pensions Fee/Retainer (Client Paid)*',
  },
  { name: 'mortgage', label: 'Mortgage', type: 'banner' },
  { name: 'mortgageInitialFee', label: 'Mortgage Initial Fee*' },
  { name: 'mortgageOngoingFee', label: 'Mortgage Ongoing Fee*' },
  { name: 'mortgageInitial', label: 'Mortgage Initial*' },
  { name: 'mortgageInitialCommission', label: 'Mortgage Initial Commission*' },
  { name: 'mortgagePayawaysReceived', label: 'Mortgage Payaways Received*' },
  { name: 'mortgageAdhocFee', label: 'Mortgage Ad-hoc Fee*' },
  { name: 'mortgageRenewal', label: 'Mortgage Renewal*' },
  { name: 'mortgageRenewalCommission', label: 'Mortgage Renewal Commission*' },
  { name: 'mortgageFundBased', label: 'Mortgage Fund Based*' },
  {
    name: 'mortgageFundBasedCommission',
    label: 'Mortgage Fund Based Commission*',
  },
  { name: 'mortgageFeesAndRetainers', label: 'Mortgage Fees and Retainers*' },
  {
    name: 'mortgageFeeRetainerClientPaid',
    label: 'Mortgage Fee/Retainer (Client Paid)*',
  },
  { name: 'generalInsurance', label: 'General Insurance', type: 'banner' },
  {
    name: 'generalInsuranceInitialFee',
    label: 'General Insurance Initial Fee*',
  },
  {
    name: 'generalInsuranceOngoingFee',
    label: 'General Insurance Ongoing Fee*',
  },
  { name: 'generalInsuranceInitial', label: 'General Insurance Initial*' },
  {
    name: 'generalInsuranceInitialCommission',
    label: 'General Insurance Initial Commission*',
  },
  {
    name: 'generalInsurancePayawaysReceived',
    label: 'General Insurance Payaways Received*',
  },
  { name: 'generalInsuranceAdhocFee', label: 'General Insurance Ad-hoc Fee*' },
  { name: 'generalInsuranceRenewal', label: 'General Insurance Renewal*' },
  {
    name: 'generalInsuranceRenewalCommission',
    label: 'General Insurance Renewal Commission*',
  },
  { name: 'generalInsuranceFundBased', label: 'General Insurance Fund Based*' },
  {
    name: 'generalInsuranceFundBasedCommission',
    label: 'General Insurance Fund Based Commission*',
  },
  {
    name: 'generalInsuranceFeesAndRetainers',
    label: 'General Insurance Fees and Retainers*',
  },
  {
    name: 'generalInsuranceFeeRetainerClientPaid',
    label: 'General Insurance Fee/Retainer (Client Paid)*',
  },
];
