import apiInterceptor from './Interceptors/apiInterceptor';

export const getAllAdjustments = async (pageNumber, pageSize) => {
  try {
    const response = await apiInterceptor.get(
      `api/adjustments?page=${pageNumber}&pageSize=${pageSize}`
    );
    return response?.data;
  } catch (error) {
    throw new Error(
      JSON.stringify(error?.response?.data?.error) ||
        'Failed to get Adjustments'
    );
  }
};

export const editAdjustments = async (id, payload) => {
  try {
    const response = await apiInterceptor.put(`api/adjustments/${id}`, payload);
    return response?.data;
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to get Adjustments');
  }
};

export const addAdjustments = async payload => {
  try {
    const response = await apiInterceptor.post(`api/adjustments`, payload);
    return response?.data;
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to add Adjustments');
  }
};

export const searchAdjustments = async (
  searchParams,
  page = 1,
  pageSize = 10
) => {
  try {
    const response = await apiInterceptor.get(
      `/api/adjustments?page=${page}&pageSize=${pageSize}&search=${searchParams}`
    );
    return response?.data || [];
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to search Adjustments');
  }
};

export const getAdjustmentsList = async () => {
  try {
    const response = await apiInterceptor.get(`api/adjustments/all`);
    return response?.data;
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to get Adjustments');
  }
};
