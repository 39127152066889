import { Button } from '@mui/material';
import { button, chip1 } from './styles';
import { Visibility } from '@mui/icons-material';
import { formatDate } from 'utils';
import { DeleteIcon } from 'assets/svgs';

export const DATA = [
  {
    id: 1,
    fileName: 'Commission Data',
    date: '25-05-24',
    category: 'Monthly',
    comments: 'Comments',
    status: 'Approved',
    salesData: '',
  },
  {
    id: 2,
    fileName: 'Commission Data',
    date: '25-05-24',
    category: 'Monthly',
    comments: 'Comments',
    status: 'Pending',
    salesData: '',
  },
  {
    id: 3,
    fileName: 'Commission Data',
    date: '25-05-24',
    category: 'Monthly',
    comments: 'Comments',
    status: 'Approved',
    salesData: '',
  },
  {
    id: 4,
    fileName: 'Commission Data',
    date: '25-05-24',
    category: 'Monthly',
    comments: 'Comments',
    status: 'Pending',
    salesData: '',
  },
  {
    id: 5,
    fileName: 'Commission Data',
    date: '25-05-24',
    category: 'Monthly',
    comments: 'Comments',
    status: 'Approved',
    salesData: '',
  },
  {
    id: 6,
    fileName: 'Commission Data',
    date: '25-05-24',
    category: 'Monthly',
    comments: 'Comments',
    status: 'Pending',
    salesData: '',
  },
  {
    id: 7,
    fileName: 'Commission Data',
    date: '25-05-24',
    category: 'Monthly',
    comments: 'Comments',
    status: 'Approved',
    salesData: '',
  },
  {
    id: 8,
    fileName: 'Commission Data',
    date: '25-05-24',
    category: 'Monthly',
    comments: 'Comments',
    status: 'Pending',
    salesData: '',
  },
];

export const dataColumns = (navigate, handleDelete) => [
  {
    headerName: 'File Name',
    field: 'fileName',
  },
  {
    headerName: 'Date',
    field: 'date',
    renderCell: params => {
      return formatDate(params?.date);
    },
  },
  {
    headerName: 'Category',
    field: 'category',
  },
  // {
  //   headerName: 'Comments',
  //   field: 'comments',
  // },
  {
    headerName: 'Validation Status',
    field: 'validationStatus',
    renderCell: row => (
      <Button
        onClick={e => {
          e.preventDefault();
          row?.validationStatus === 'Pending'
            ? navigate('/error-logs?Validation=true')
            : null;
        }}
        variant="outlined"
        size="small"
        sx={chip1(row)}
      >
        {row?.validationStatus}
      </Button>
    ),
  },
  {
    headerName: 'Commission Data',
    renderCell: row => {
      return (
        <Button
          variant="text"
          startIcon={<Visibility />}
          onClick={() => navigate(`/upload/${row?.id}`)}
          sx={button}
        >
          View
        </Button>
      );
    },
  },
  {
    headerName: 'Action',
    renderCell: row => (
      <Button
        variant="text"
        startIcon={<DeleteIcon />}
        onClick={() => handleDelete(row.id)}
        sx={button}
      >
        Delete
      </Button>
    ),
  },
];
