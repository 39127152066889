import React from 'react';
import { Box, Grid, Typography, Button, IconButton } from '@mui/material';
import styles from './deleteProduct.module.scss';
import { Close } from '@mui/icons-material';
import ModalContainer from 'components/common/ModalContainer/ModalContainer';
import { Toaster, toast } from 'react-hot-toast';
import { deleteProductCategory } from '_redux/services/categories.service';

const DeleteProduct = ({ selectedRow, onClose, open, refreshData }) => {
  const handleDelete = async () => {
    try {
      const response = await deleteProductCategory(selectedRow.id);

      if (!response.error) {
        toast.success('Product deleted successfully!');
        refreshData();
        onClose();
      }
    } catch (error) {
      toast.error('An error occurred while deleting the product');
    }
  };

  return (
    <>
      <Toaster />
      <ModalContainer open={open}>
        <Box className={styles.earlyPaymentContainer}>
          <Box className={styles.heading}>
            <Typography variant="h6">
              Are you sure you want to delete
            </Typography>
            <IconButton onClick={onClose} className={styles.closeButton}>
              <Close />
            </IconButton>
          </Box>

          <Box className={styles.formContainer}>
            <Grid container p={4} spacing={2}>
              <Typography variant="p">
                Product Category: {selectedRow.productCategory}
              </Typography>
            </Grid>

            <Box className={styles.actionButtons}>
              <Button
                variant="outlined"
                sx={{
                  textTransform: 'none',
                  borderRadius: '8px',
                  borderColor: '#003366',
                  color: '#003366',
                  '&:hover': {
                    borderColor: '#002244',
                    color: 'white',
                    backgroundColor: '#003366',
                  },
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="primary"
                sx={{
                  textTransform: 'none',
                  borderRadius: '8px',
                  border: '1px solid rgb(200, 108, 91)',
                  backgroundColor: '#003366',
                  color: 'white',
                  padding: '8px 16px',
                  marginRight: '15px',
                  '&:hover': {
                    backgroundColor: '#002244',
                    color: 'white',
                  },
                }}
                onClick={handleDelete}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Box>
      </ModalContainer>
    </>
  );
};

export default DeleteProduct;
