import { useState } from 'react';
import {
  Checkbox,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Divider,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

export const getProductColumns = ({
  selectedRows,
  handleRowSelection,
  handleEditClick,
  handleViewClick,
  handleDelete,
}) => {
  const [checkedAll, setCheckedAll] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleAllChecked = () => {
    setCheckedAll(!checkedAll);
  };

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  return [
    {
      headerName: (
        <Checkbox
          size="small"
          checked={checkedAll}
          onChange={handleAllChecked}
        />
      ),
      field: 'selection',
      width: 50,
      renderCell: row => (
        <Checkbox
          size="small"
          checked={selectedRows.includes(row.id)}
          onChange={() => handleRowSelection(row.id)}
        />
      ),
    },
    {
      headerName: 'Product Category',
      field: 'productCategory',
      renderCell: row => <Box>{row.productCategory}</Box>,
    },
    {
      headerName: 'Product Type',
      field: 'productType',
      renderCell: row => (
        <Box>
          {Object.values(row.productType).map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </Box>
      ),
    },
    {
      headerName: 'Income Type',
      field: 'incomeType',
      renderCell: row => (
        <Box>
          {Object.values(row.incomeType).map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </Box>
      ),
    },
    {
      headerName: 'Action',
      field: 'action',
      renderCell: row => (
        <>
          <IconButton onClick={event => handleMenuOpen(event, row)}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && selectedRow?.id === row.id}
            onClose={handleMenuClose}
            sx={{
              '& .MuiPaper-root': {
                borderRadius: '12px',
                backgroundColor: '#fff',
                boxShadow: 'none',
                minWidth: '120px',
                border: '1px solid rgba(0,0,0,0.1)',
              },
            }}
          >
            <MenuItem
              onClick={() => {
                handleEditClick(row);
                handleMenuClose();
              }}
              sx={{ display: 'flex', gap: '8px' }}
            >
              <EditIcon fontSize="small" />
              Edit
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                handleViewClick(row);
                handleMenuClose();
              }}
              sx={{ display: 'flex', gap: '8px' }}
            >
              <VisibilityIcon fontSize="small" />
              View
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                handleDelete(row);
                handleMenuClose();
              }}
              sx={{ display: 'flex', gap: '8px', color: 'red' }}
            >
              <DeleteIcon fontSize="small" />
              Delete
            </MenuItem>
          </Menu>
        </>
      ),
    },
  ];
};
