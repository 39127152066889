import { useState } from 'react';
import {
  MenuItem,
  Select,
  Checkbox,
  FormControl,
  styled,
  TextField,
  TableCell,
  Box,
  InputAdornment,
  Input,
} from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { KeyboardArrowDown } from '@mui/icons-material';

export const getAdvisorColumns = ({
  selectedRows,
  handleRowSelection,
  handleValueChange,
  isEditMode,
  handleUpdateRow,
  advisorFields,
}) => {
  const [editingCell, setEditingCell] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [checkedAll, setCheckedAll] = useState(false);
  const [updatedRow, setUpdatedRow] = useState(null);

  if (editingCell) {
    console.log('');
  }

  const handleKeyDown = (event, row, field, value) => {
    if (event.key === 'Enter') {
      handleBlur(row, field, value);
    }
  };

  const handleChange = (row, field, value) => {
    if (value && formErrors[row.id]?.[field]) {
      setFormErrors(prev => ({
        ...prev,
        [row.id]: {
          ...prev[row.id],
          [field]: false,
        },
      }));
    }

    let error = false;
    if (!value && field !== 'comments') {
      error = 'This field is required';
    }

    if (field === 'email' && value && !/\S+@\S+\.\S+/.test(value)) {
      error = 'Please enter a valid email.';
    }

    setFormErrors(prev => ({
      ...prev,
      [row.id]: {
        ...prev[row.id],
        [field]: error || false,
      },
    }));

    if (isEditMode) {
      const newUpdatedRow =
        updatedRow?.id === row.id
          ? {
              ...updatedRow,
              valuesChanged: { ...updatedRow.valuesChanged, [field]: value },
            }
          : { id: row.id, valuesChanged: { [field]: value } };

      setUpdatedRow(newUpdatedRow);
      handleUpdateRow(newUpdatedRow);
    }

    handleValueChange(row.id, field, value);
  };

  const handleBlur = (row, field, value) => {
    if (!value) {
      setFormErrors(prev => ({
        ...prev,
        [row.id]: {
          ...prev[row.id],
          [field]: 'This field is required',
        },
      }));
    } else {
      setFormErrors(prev => ({
        ...prev,
        [row.id]: {
          ...prev[row.id],
          [field]: false,
        },
      }));
    }

    setEditingCell(null);
    handleValueChange(row.id, field, value);
  };

  const handleAllChecked = () => {
    setCheckedAll(!checkedAll);
  };

  const formatFieldName = field => {
    return field
      .replace(/&/g, 'And')
      .replace(/[\s-]+/g, '')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join('');
  };

  return [
    {
      headerName: (
        <Checkbox
          size="small"
          checked={checkedAll}
          onChange={handleAllChecked}
        />
      ),
      field: 'selection',
      width: 50,
      renderCell: row => (
        <Checkbox
          sx={{
            backgroundColor: formErrors[row.id]?.length
              ? '#FFCDD2'
              : 'transparent',
            color: formErrors[row.id]?.length ? 'red' : 'inherit',
            border: formErrors[row.id]?.length ? '1px solid red' : 'none',
          }}
          size="small"
          checked={checkedAll || selectedRows.includes(row.id)}
          onChange={() => handleRowSelection(row.id)}
        />
      ),
    },
    { headerName: 'Role', field: 'role' },
    { headerName: 'ID', field: 'id' },
    {
      headerName: 'Name',
      field: 'name',
      renderCell: row => (
        <TextField
          disabled={!isEditMode}
          value={row.name}
          onChange={e => handleChange(row, 'name', e.target.value)}
          onBlur={() => handleBlur(row, 'name', row.name)}
          onKeyDown={e => handleKeyDown(e, row, 'name', row.name)}
          autoFocus
          size="medium"
          error={!!formErrors[row.id]?.name}
          helperText={formErrors[row.id]?.name ? 'This field is required' : ''}
          sx={{
            width: '200px',
            backgroundColor: formErrors[row.id]?.name
              ? '#FFCDD2'
              : 'transparent',
            color: formErrors[row.id]?.name ? 'red' : 'inherit',
            border: formErrors[row.id]?.name ? '1px solid red' : 'none',
          }}
        />
      ),
    },
    {
      headerName: 'Role Details',
      field: 'roleDetails',
      renderCell: row => (
        <FormControl disabled={!isEditMode} fullWidth variant="outlined">
          <StyledSelect
            value={row.roleDetails}
            IconComponent={KeyboardArrowDown}
            onChange={e => handleChange(row, 'roleDetails', e.target.value)}
            error={!!formErrors[row.id]?.roleDetails}
          >
            <MenuItem value="Buyout">Buyout</MenuItem>
            <MenuItem value="Own Account">Own Account</MenuItem>
            <MenuItem value="EX-PBO">EX-BPO</MenuItem>
            <MenuItem value="EX">EX</MenuItem>
            <MenuItem value="N/A">N/A</MenuItem>
          </StyledSelect>
        </FormControl>
      ),
    },
    {
      headerName: 'Email',
      field: 'email',
      renderCell: row => (
        <TextField
          disabled={!isEditMode}
          value={row.email}
          onChange={e => handleChange(row, 'email', e.target.value)}
          onBlur={() => handleBlur(row, 'email', row.email)}
          onKeyDown={e => handleKeyDown(e, row, 'email', row.email)}
          autoFocus
          size="small"
          error={!!formErrors[row.id]?.email}
          helperText={
            formErrors[row.id]?.email ? 'Please enter a valid email' : ''
          }
          sx={{
            width: '200px',
            backgroundColor: formErrors[row.id]?.email
              ? '#FFCDD2'
              : 'transparent',
            color: formErrors[row.id]?.email ? 'red' : 'inherit',
            border: formErrors[row.id]?.email ? '1px solid red' : 'none',
          }}
        />
      ),
    },
    {
      headerName: 'Gross/Net',
      field: 'grossNet',
      renderCell: row => (
        <FormControl disabled={!isEditMode} fullWidth variant="outlined">
          <StyledSelect
            value={row.grossNet}
            IconComponent={KeyboardArrowDown}
            onChange={e => handleChange(row, 'grossNet', e.target.value)}
            error={!!formErrors[row.id]?.grossNet}
          >
            <MenuItem value="Gross">Gross</MenuItem>
            <MenuItem value="Net">Net</MenuItem>
          </StyledSelect>
        </FormControl>
      ),
    },
    {
      headerName: 'Effective From',
      field: 'effectiveFrom',
      renderCell: row => (
        <TableCell
          sx={{
            backgroundColor: formErrors[row.id]?.effectiveFrom
              ? '#FFCDD2'
              : 'transparent',
            color: formErrors[row.id]?.effectiveFrom ? 'red' : 'inherit',
            border: formErrors[row.id]?.effectiveFrom
              ? '1px solid red'
              : 'none',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              disabled={!isEditMode}
              label="Effective From*"
              sx={{ width: '150px' }}
              value={dayjs(row.effectiveFrom)}
              views={['year', 'month', 'day']}
              minDate={dayjs('2022-01-01')}
              maxDate={dayjs('2030-12-31')}
              onChange={newDate =>
                handleChange(
                  row,
                  'effectiveFrom',
                  dayjs(newDate).format('YYYY-MM-DD')
                )
              }
              slots={{
                textField: props => (
                  <TextField
                    {...props}
                    error={!!formErrors[row.id]?.effectiveFrom}
                    helperText={
                      formErrors[row.id]?.effectiveFrom
                        ? 'This field is required'
                        : ''
                    }
                  />
                ),
              }}
            />
          </LocalizationProvider>
        </TableCell>
      ),
    },
    {
      headerName: 'Statements',
      field: 'statements',
      renderCell: row => (
        <TableCell
          sx={{
            backgroundColor: formErrors[row.id]?.statements
              ? '#FFCDD2'
              : 'transparent',
            color: formErrors[row.id]?.statements ? 'red' : 'inherit',
            border: formErrors[row.id]?.statements ? '1px solid red' : 'none',
          }}
        >
          <FormControl disabled={!isEditMode} fullWidth variant="outlined">
            <StyledSelect
              value={row.statements}
              IconComponent={KeyboardArrowDown}
              onChange={e => handleChange(row, 'statements', e.target.value)}
              error={!!formErrors[row.id]?.statements}
            >
              <MenuItem value="Full Payment Due">Full Payment Due</MenuItem>
              <MenuItem value="Statement Not Required">
                Statement Not Required
              </MenuItem>
            </StyledSelect>
          </FormControl>
        </TableCell>
      ),
    },
    {
      headerName: 'Comments',
      field: 'comments',
      renderCell: row => (
        <TableCell
          onClick={() => isEditMode && setEditingCell(`comments-${row.id}`)}
          sx={{
            backgroundColor: formErrors[row.id]?.comments
              ? '#FFCDD2'
              : 'transparent',
            color: formErrors[row.id]?.comments ? 'red' : 'inherit',
            border: formErrors[row.id]?.comments ? '1px solid red' : 'none',
          }}
        >
          {row.comments}
        </TableCell>
      ),
    },
    ...advisorFields.flatMap(({ productCategory, incomeType }) => [
      {
        headerName: '',
        field: '',
        renderCell: row => (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="full"
            height="full"
          >
            <Box
              style={{
                backgroundColor: '#0A2756',
                color: 'white',
                width: '100%',
                minHeight: '140%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '14px',
                padding: '8px',
                textAlign: 'center',
              }}
            >
              {productCategory}
            </Box>
          </Box>
        ),
      },
      ...incomeType.map(type => ({
        headerName: type,
        field: `${productCategory.replace(/\s+/g, '').toLowerCase()}${formatFieldName(type)}`,
        renderCell: row => (
          <Input
            disabled={!isEditMode}
            type={(!!isEditMode && 'number') || 'null'}
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
            value={
              row[
                `${productCategory.replace(/\s+/g, '').toLowerCase()}${formatFieldName(type)}`
              ] || '--'
            }
            onChange={e =>
              handleChange(
                row,
                `${productCategory.replace(/\s+/g, '').toLowerCase()}${formatFieldName(type)}`,
                Math.max(0, parseInt(e.target.value) || 0)
              )
            }
            autoFocus
            inputProps={{ min: 0 }}
            size="small"
            sx={{
              width: '150px',
            }}
          />
        ),
      })),
    ]),
  ];
};

const StyledSelect = styled(Select)({
  '& .MuiSelect-select': {
    padding: '8px 32px 8px 12px',
    backgroundColor: '#f8f9fa',
    border: 'none',
    borderRadius: '4px',
    fontSize: '14px',
    color: '#495057',
    '&:focus': {
      backgroundColor: '#f8f9fa',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
});
