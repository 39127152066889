import apiInterceptor from './Interceptors/apiInterceptor';

export const getAllAdjustmentTypes = async (pageNumber, pageSize) => {
  try {
    const response = await apiInterceptor.get(
      `api/adjustmentTypes?page=${pageNumber}&pageSize=${pageSize}`
    );
    return response?.data;
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to get Adjustment Types');
  }
};

export const editAdjustmentTypes = async (id, payload) => {
  try {
    const response = await apiInterceptor.put(
      `api/adjustmentTypes/${id}`,
      payload
    );
    return response?.data;
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to update Adjustment Types');
  }
};

export const addAdjustmentTypes = async payload => {
  try {
    const response = await apiInterceptor.post(`api/adjustmentTypes`, payload);
    return response?.data;
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to add Adjustment Types');
  }
};

export const searchAdjustmentTypes = async (
  searchParams,
  page = 1,
  pageSize = 10
) => {
  try {
    const response = await apiInterceptor.get(
      `/api/adjustmentTypes?page=${page}&pageSize=${pageSize}&search=${searchParams}`
    );
    return response?.data || [];
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to search Adjustment Types');
  }
};

export const getAdjustmentTypesList = async () => {
  try {
    const response = await apiInterceptor.get(`api/adjustmentTypes/all`);
    return response?.data;
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to get Adjustment Types List');
  }
};
