import apiInterceptor from './Interceptors/apiInterceptor';

export const getAllIntroducers = async (pageNumber, pageSize) => {
  try {
    const response = await apiInterceptor.get(
      `api/introducers?page=${pageNumber}&pageSize=${pageSize}`
    );
    return response?.data;
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to get Intorducers');
  }
};

export const addIntroducer = async payload => {
  try {
    const response = await apiInterceptor.post(`api/introducers`, payload);
    return response?.data;
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to add Intorducer');
  }
};

export const editIntroducer = async (id, payload) => {
  try {
    const response = await apiInterceptor.put(`api/introducers/${id}`, payload);
    return response?.data;
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to update Intorducer');
  }
};

export const getIntroducerorById = async id => {
  try {
    const response = await apiInterceptor.get(`api/introducers/${id}`);
    return response?.data;
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to get Intorducer');
  }
};

export const searchIntroducers = async (
  searchParams,
  page = 1,
  pageSize = 10
) => {
  try {
    const response = await apiInterceptor.get(
      `/api/introducers?page=${page}&pageSize=${pageSize}&search=${searchParams}`
    );
    return response?.data || [];
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to search Intorducers');
  }
};

export const getIntroducersList = async () => {
  try {
    const response = await apiInterceptor.get(`api/introducers/all`);
    return response?.data;
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to get Intorducers List');
  }
};
