import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  IconButton,
  MenuItem,
  TextField,
} from '@mui/material';
import styles from './addNewIntroducer.module.scss';
import { Close } from '@mui/icons-material';
import ModalContainer from 'components/common/ModalContainer/ModalContainer';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Toaster, toast } from 'react-hot-toast';
import { addIntroducer } from '_redux/services/introducer.service';

const AddNewIntroducer = ({ handleClose, onClose, open, refreshData }) => {
  const [formValues, setFormValues] = useState({
    name: '',
    roleDetails: '',
    email: '',
    grossNet: '',
    effectiveFrom: null,
    statements: '',
    comments: '',
  });

  const [formErrors, setFormErrors] = useState({
    name: false,
    roleDetails: false,
    email: false,
    grossNet: false,
    effectiveFrom: false,
    statements: false,
  });

  const handleSubmit = e => {
    e.preventDefault();

    const { name, roleDetails, email, effectiveFrom, statements, grossNet } =
      formValues;

    const errors = {};

    if (!name) errors.name = true;
    if (!roleDetails) errors.roleDetails = true;
    if (!email) errors.email = true;
    if (!grossNet) errors.grossNet = true;
    if (effectiveFrom === null) errors.effectiveFrom = true;
    if (!statements) errors.statements = true;
    if (email && !/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Please enter a valid email.';
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      sendDatatoAPI();
    }
  };

  const handleFormChange = e => {
    setFormErrors({ ...formErrors, [e.target.name]: false });
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const sendDatatoAPI = async () => {
    try {
      const response = await addIntroducer(formValues);

      if (response.name) {
        toast.success('Introducer added successfully!');
        onClose();
        refreshData();
      }
    } catch (error) {
      toast.error(`Error: ${error || 'Something went wrong.'}`);
    }
  };

  const handleDateChange = date => {
    setFormErrors({ ...formErrors, effectiveFrom: false });
    setFormValues({
      ...formValues,
      effectiveFrom: date || null,
    });
  };

  return (
    <>
      <Toaster />
      <ModalContainer open={open}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box className={styles.earlyPaymentContainer}>
            <Box className={styles.heading}>
              <Typography variant="h6" className={styles.title}>
                Add New Introducer
              </Typography>
              <IconButton onClick={handleClose} className={styles.closeButton}>
                <Close />
              </IconButton>
            </Box>

            <Box className={styles.formContainer}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    name={'role'}
                    label="Role*"
                    variant="outlined"
                    value={'Introducer'}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    label="Name*"
                    variant="outlined"
                    name={'name'}
                    value={formValues.name}
                    onChange={handleFormChange}
                    error={formErrors.name}
                    helperText={formErrors.name ? 'This field is required' : ''}
                    className={styles.textField}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    select
                    fullWidth
                    label={`Role Details*`}
                    variant="outlined"
                    name={'roleDetails'}
                    value={formValues.roleDetails}
                    onChange={handleFormChange}
                    error={formErrors.roleDetails}
                    helperText={
                      formErrors.roleDetails ? 'This field is required' : ''
                    }
                  >
                    <MenuItem value="Buyout">Buyout</MenuItem>
                    <MenuItem value="Own Account">Own Account</MenuItem>
                    <MenuItem value="EX-PBO">EX-BPO</MenuItem>
                    <MenuItem value="EX">EX</MenuItem>
                    <MenuItem value="N/A">N/A</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    label="Email*"
                    variant="outlined"
                    name={'email'}
                    value={formValues.email}
                    onChange={handleFormChange}
                    error={formErrors.email}
                    helperText={
                      formErrors.email
                        ? formErrors.email === true
                          ? 'This field is required'
                          : formErrors.email
                        : ''
                    }
                    className={styles.textField}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    select
                    fullWidth
                    label={`Gross or Net*`}
                    variant="outlined"
                    name={'grossNet'}
                    value={formValues.grossNet}
                    onChange={handleFormChange}
                    error={formErrors.grossNet}
                    helperText={
                      formErrors.grossNet ? 'This field is required' : ''
                    }
                  >
                    <MenuItem key={'Gross'} value={'Gross'}>
                      Gross
                    </MenuItem>
                    <MenuItem key={'Net'} value={'Net'}>
                      Net
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} md={12}>
                  <MobileDatePicker
                    label="Effective From*"
                    sx={{ width: '-webkit-fill-available' }}
                    value={formValues.effectiveFrom}
                    views={['year', 'month', 'day']}
                    minDate={dayjs('2022-01-01')}
                    maxDate={dayjs('2030-12-31')}
                    onChange={handleDateChange}
                    slots={{
                      textField: props => (
                        <TextField
                          {...props}
                          error={formErrors.effectiveFrom}
                          helperText={
                            formErrors.effectiveFrom
                              ? 'This field is required'
                              : ''
                          }
                        />
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    select
                    fullWidth
                    label={`Statements*`}
                    variant="outlined"
                    name={'statements'}
                    value={formValues.statements}
                    onChange={handleFormChange}
                    error={formErrors.statements}
                    helperText={
                      formErrors.statements ? 'This field is required' : ''
                    }
                  >
                    <MenuItem key={'FullPaymentDue'} value={'Full Payment Due'}>
                      Full Payment Due
                    </MenuItem>
                    <MenuItem
                      key={'StatementNotRequired'}
                      value={'Statement Not Required'}
                    >
                      Statement Not Required
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Comments"
                    variant="outlined"
                    name={'comments'}
                    value={formValues.comments}
                    onChange={handleFormChange}
                    className={styles.textField}
                  />
                </Grid>
              </Grid>

              <Box className={styles.actionButtons}>
                <Button
                  variant="outlined"
                  className={styles.rejectButton}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  className={styles.acceptButton}
                >
                  Add
                </Button>
              </Box>
            </Box>
          </Box>
        </LocalizationProvider>
      </ModalContainer>
    </>
  );
};

export default AddNewIntroducer;
