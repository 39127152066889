import { Box, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

export const getPayoutsColumns = ({ onView }) => {
  return [
    {
      headerName: 'Advisors ID',
      field: 'id',
      renderCell: row => <Box>{row.id}</Box>,
    },
    {
      headerName: 'Advisors Name',
      field: 'name',
      renderCell: row => <Box>{row.name}</Box>,
    },
    {
      headerName: 'Total Payout',
      field: 'totalPayout',
      renderCell: row => `£${row?.totalPayout || 0}`,
    },
    {
      headerName: 'Adjustment',
      field: 'adjustment',
      renderCell: row => `${row?.adjustments[0].name || 'N/A'}`,
    },
    {
      headerName: 'Adjustment Type',
      field: 'type',
      renderCell: row => `${row?.adjustments[0].type || 'N/A'}`,
    },
    {
      headerName: 'Frequency',
      field: 'frequency',
      renderCell: row => `${row?.adjustments[0].frequency || 'N/A'}`,
    },
    {
      headerName: 'Value',
      field: 'value',
      renderCell: row => `${row?.adjustments[0].value || 0}`,
    },
    {
      headerName: 'Net Payout',
      field: 'netPayout',
      renderCell: row => `£${row?.netPayout || 0}`,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      renderCell: row => (
        <IconButton onClick={() => onView(row)} color="#003466">
          <VisibilityIcon />
        </IconButton>
      ),
    },
  ];
};
