import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import styles from './categoriesSetUp.module.scss';
import { GenericTable } from 'components/common/GenericTable';
import { getProductColumns } from 'utils/Product';
import AddNewProduct from 'components/AddNewProduct/AddNewProduct';
import { getAllCategories } from '_redux/services/categories.service';
import DeleteProduct from 'components/DeleteProduct/DeleteProduct';

const CategoriesSetUp = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);
  const [addNewProductModal, setAddNewProductModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isViewMode, setIsViewMode] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [deleteMode, setDeleteMode] = useState(false);

  const getCategoriesData = useCallback(async () => {
    const response = await getAllCategories(page, rowsPerPage);
    const total = response.total;
    setTotalData(total);

    const lastPage = Math.ceil(total / rowsPerPage) - 1;
    if (page === lastPage) {
      const remainingItems = total % rowsPerPage || rowsPerPage;
      setData(response.data.slice(0, remainingItems));
    } else {
      setData(response.data);
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    getCategoriesData();
  }, [getCategoriesData]);

  const handleRowSelection = id => {
    setSelectedRows(prev =>
      prev.includes(id) ? prev.filter(rowId => rowId !== id) : [...prev, id]
    );
  };

  const handleDeleteModalClose = () => {
    setSelectedRow(null);
    setDeleteMode(false);
  };
  const handleModalClose = () => {
    setAddNewProductModal(false);
    setSelectedRow(null);
    setIsViewMode(false);
    setIsEditMode(false);
    getAllCategories();
  };

  const handleEditClick = row => {
    setSelectedRow(row);
    setAddNewProductModal(true);
    setIsViewMode(false);
    setIsEditMode(true);
  };

  const handleViewClick = row => {
    setSelectedRow(row);
    setAddNewProductModal(true);
    setIsViewMode(true);
    setIsEditMode(false);
  };

  const handleEditToggle = () => {
    setIsViewMode(false);
    setIsEditMode(true);
  };

  const handleDelete = row => {
    setSelectedRow(row);
    setDeleteMode(true);
  };

  const columns = getProductColumns({
    selectedRows,
    handleRowSelection,
    isEditMode,
    handleEditClick,
    handleViewClick,
    handleDelete,
  });

  return (
    <Box className={styles.dashboardContainer}>
      <Box>
        <Grid container>
          <Grid m={4} item xs={12}>
            <GenericTable
              title={'Categories Setup'}
              isCategory={true}
              refreshData={getCategoriesData}
              setData={setData}
              pageConfig={{
                page,
                setPage,
                rowsPerPage,
                setRowsPerPage,
                totalRows: totalData,
              }}
              addNewProduct={true}
              onAddNewProduct={() => setAddNewProductModal(true)}
              columns={columns}
              data={data}
            />
            {addNewProductModal && (
              <AddNewProduct
                isEditMode={isEditMode}
                isViewMode={isViewMode}
                onClose={handleModalClose}
                handleEditToggle={handleEditToggle}
                open={addNewProductModal}
                selectedRow={selectedRow}
                refreshData={getCategoriesData}
              />
            )}
            {deleteMode && (
              <DeleteProduct
                selectedRow={selectedRow}
                open={deleteMode}
                onClose={handleDeleteModalClose}
                refreshData={getCategoriesData}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CategoriesSetUp;
