import React, { useCallback, useEffect, useState } from 'react';
import { GenericTable } from 'components/common/GenericTable';
import { getAllAdjustmentTypes } from '_redux/services/adjustmentTypes.service';
import { editAdjustmentTypes } from '_redux/services/adjustmentTypes.service';
import { Toaster, toast } from 'react-hot-toast';
import { getAdjustmentTypesColumns } from 'utils/AdjustmentTypes';
import './adjustmentTypes.module.scss';
import SetUpAdjustments from 'components/SetUpAdjustments/SetUpAdjustments';

const AdjustmentTypes = ({ handleTabChange, tab, tabs }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState();
  const [isSetUpModalOpen, setisIsSetUpModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [updatedRow, setUpdatedRow] = useState({});

  const getAdjustmentTypesData = useCallback(async () => {
    try {
      const response = await getAllAdjustmentTypes(page, rowsPerPage);
      const total = response.total;
      setTotalData(total);

      setData(prevData => {
        const lastPage = Math.ceil(total / rowsPerPage) - 1;
        if (page === lastPage) {
          const remainingItems = total % rowsPerPage || rowsPerPage;
          return response.data.slice(0, remainingItems);
        }
        return response.data;
      });
    } catch (error) {
      console.error('Error fetching Adjustment Types:', error);
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    getAdjustmentTypesData();
  }, [getAdjustmentTypesData]);

  const handleRowSelection = id => {
    setSelectedRows(prev =>
      prev.includes(id) ? prev.filter(rowId => rowId !== id) : [...prev, id]
    );
  };

  const handleValueChange = (id, field, value) => {
    setData(prevData =>
      prevData.map(row => (row.id === id ? { ...row, [field]: value } : row))
    );
  };

  const handleModalOpen = () => {
    setisIsSetUpModalOpen(true);
  };

  const handleModalClose = () => {
    setisIsSetUpModalOpen(false);
  };

  const handleEditToggle = () => {
    setIsEditMode(prev => !prev);
  };
  const handelCancelEdit = () => {
    setIsEditMode(false);
    getAdjustmentTypesData();
  };

  const handleSaveChanges = async () => {
    try {
      const newRow = await editAdjustmentTypes(
        updatedRow.id,
        updatedRow.valuesChanged
      );

      if (newRow.id) {
        toast.success('Row updated successfully!');
        handleEditToggle();
        getAdjustmentTypesData();
      }
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const handleUpdateRow = row => {
    setUpdatedRow(row);
  };

  const columns = getAdjustmentTypesColumns({
    selectedRows,
    handleRowSelection,
    handleValueChange,
    isEditMode,
    handleUpdateRow,
  });

  return (
    <>
      <Toaster />
      <GenericTable
        title="Adjustment Types"
        refreshData={getAdjustmentTypesData}
        handleEditToggle={handleEditToggle}
        handleCancelEdit={handelCancelEdit}
        handleSaveChanges={handleSaveChanges}
        isEditMode={isEditMode}
        showEdit={true}
        columns={columns}
        data={data}
        setData={setData}
        isAdjustmentTypes={true}
        handleModalOpen={handleModalOpen}
        pageConfig={{
          page,
          setPage,
          rowsPerPage,
          setRowsPerPage,
          totalRows: totalData,
        }}
        handleTabChange={handleTabChange}
        tab={tab}
        isTabs={true}
        tabs={tabs}
      />

      {isSetUpModalOpen && (
        <SetUpAdjustments
          onClose={handleModalClose}
          open={isSetUpModalOpen}
          handleClose={handleModalClose}
          refreshData={getAdjustmentTypesData}
        />
      )}
    </>
  );
};

export default AdjustmentTypes;
