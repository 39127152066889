import { useEffect, useState } from 'react';
import {
  MenuItem,
  Checkbox,
  TextField,
  Box,
  Chip,
  Grid,
  FormControl,
  styled,
  Select,
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import { getAdvisorsList } from '_redux/services/advisor.service';
import { getIntroducersList } from '_redux/services/introducer.service';
import { getAdjustmentTypesList } from '_redux/services/adjustmentTypes.service';

export const StyledSelect = styled(Select)({
  '& .MuiSelect-select': {
    padding: '8px 32px 8px 12px',
    backgroundColor: '#f8f9fa',
    border: 'none',
    borderRadius: '4px',
    fontSize: '14px',
    color: '#495057',
    '&:focus': {
      backgroundColor: '#f8f9fa',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
});
export const getAdjustmentsColumns = ({
  selectedRows,
  handleRowSelection,
  handleValueChange,
  isEditMode,
  handleUpdateRow,
}) => {
  const [formErrors, setFormErrors] = useState({});
  const [checkedAll, setCheckedAll] = useState(false);
  const [advisorsList, setAdvisorsList] = useState([]);
  const [introducersList, setIntroducersList] = useState([]);
  const [adjustmentsList, setAdjustmentsList] = useState([]);
  const [updatedRow, setUpdatedRow] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const [advisorsData, introducersData, adjustmentsTypesData] =
        await Promise.all([
          getAdvisorsList(),
          getIntroducersList(),
          getAdjustmentTypesList(),
        ]);
      setAdvisorsList(advisorsData);
      setIntroducersList(introducersData);
      setAdjustmentsList(adjustmentsTypesData);
    };
    fetchData();
  }, []);

  const handleAllChecked = () => {
    setCheckedAll(!checkedAll);
  };

  const handleChange = (row, field, value) => {
    if (value && formErrors[row.id]?.[field]) {
      setFormErrors(prev => ({
        ...prev,
        [row.id]: {
          ...prev[row.id],
          [field]: false,
        },
      }));
    }

    let error = false;
    if (!value && field !== 'note') {
      error = 'This field is required';
    }

    setFormErrors(prev => ({
      ...prev,
      [row.id]: {
        ...prev[row.id],
        [field]: error || false,
      },
    }));

    if (isEditMode) {
      let updatedValue = value;

      // For advisors and introducers, store only the IDs
      if (field === 'advisors' || field === 'introducers') {
        updatedValue = value.map(item => item.id);
      }

      const newUpdatedRow =
        updatedRow?.id === row.id
          ? {
              ...updatedRow,
              valuesChanged: {
                ...updatedRow.valuesChanged,
                [field]: updatedValue,
              },
            }
          : { id: row.id, valuesChanged: { [field]: updatedValue } };

      setUpdatedRow(newUpdatedRow);
      handleUpdateRow(newUpdatedRow);
    }

    handleValueChange(row.id, field, value);
  };

  const handleBlur = (row, field, value) => {
    if (!value && field !== 'note') {
      setFormErrors(prev => ({
        ...prev,
        [row.id]: {
          ...prev[row.id],
          [field]: 'This field is required',
        },
      }));
    } else {
      setFormErrors(prev => ({
        ...prev,
        [row.id]: {
          ...prev[row.id],
          [field]: false,
        },
      }));
    }

    handleValueChange(row.id, field, value);
  };

  const handleKeyDown = (event, row, field, value) => {
    if (event.key === 'Enter') {
      handleBlur(row, field, value);
    }
  };

  const handleDeleteItem = (row, field, itemId) => {
    const updatedItems = row[field].filter(item => item.id !== itemId);
    handleChange(row, field, updatedItems);
  };

  const renderEditableField = (row, field, options, label) => (
    <>
      <Grid item xs={12} md={12}>
        <TextField
          select
          fullWidth
          label={label}
          variant="outlined"
          value=""
          onChange={e => {
            const selectedItem = options.find(
              item => item.id === e.target.value
            );
            handleChange(row, field, [...row[field], selectedItem]);
          }}
          error={!!formErrors[row.id]?.[field]}
          helperText={formErrors[row.id]?.[field] || ''}
        >
          {options.map(item => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid paddingBlock={1} item xs={12} md={12}>
        {row[field].map(item => (
          <Chip
            key={item.id}
            label={item.name}
            onDelete={() => handleDeleteItem(row, field, item.id)}
            deleteIcon={<CloseIcon />}
          />
        ))}
      </Grid>
    </>
  );

  return [
    {
      headerName: (
        <Checkbox
          size="small"
          checked={checkedAll}
          onChange={handleAllChecked}
        />
      ),
      field: 'selection',
      width: 50,
      renderCell: row => (
        <Checkbox
          size="small"
          checked={checkedAll || selectedRows.includes(row.id)}
          onChange={() => handleRowSelection(row.id)}
        />
      ),
    },
    {
      headerName: 'Advisor Name',
      field: 'advisors',
      renderCell: row =>
        isEditMode ? (
          renderEditableField(row, 'advisors', advisorsList, 'Advisors')
        ) : (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {row.advisors.map(advisor => (
              <Chip key={advisor.id} label={advisor.name} />
            ))}
          </Box>
        ),
    },
    {
      headerName: 'Introducer Name',
      field: 'introducers',
      renderCell: row =>
        isEditMode ? (
          renderEditableField(
            row,
            'introducers',
            introducersList,
            'Introducers'
          )
        ) : (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {row.introducers.map(introducer => (
              <Chip key={introducer.id} label={introducer.name} />
            ))}
          </Box>
        ),
    },
    {
      headerName: 'IOB',
      field: 'IOB',
    },
    {
      headerName: 'Adjustment Name',
      field: 'adjustmentType',
      renderCell: row => (
        <FormControl disabled={!isEditMode} fullWidth variant="outlined">
          <TextField
            disabled={!isEditMode}
            select
            value={row.adjustmentType}
            IconComponent={KeyboardArrowDown}
            onChange={e => handleChange(row, 'adjustmentType', e.target.value)}
            error={!!formErrors[row.id]?.adjustmentType}
          >
            {adjustmentsList.map(adjustment => (
              <MenuItem key={adjustment.id} value={adjustment.id}>
                {adjustment.title}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      ),
    },
    {
      headerName: 'Description',
      field: 'description',
      renderCell: row => (
        <TextField
          disabled={!isEditMode}
          fullWidth
          value={row.description}
          onChange={e => handleChange(row, 'description', e.target.value)}
          onBlur={() => handleBlur(row, 'description', row.description)}
          onKeyDown={e => handleKeyDown(e, row, 'description', row.description)}
          sx={{ width: '200px' }}
          error={!!formErrors[row.id]?.description}
          helperText={formErrors[row.id]?.description || ''}
        />
      ),
    },
    {
      headerName: 'Value',
      field: 'value',
      renderCell: row => (
        <TextField
          disabled={!isEditMode}
          type="number"
          inputProps={{ min: 0 }}
          value={row.value}
          onChange={e => handleChange(row, 'value', e.target.value)}
          onBlur={() => handleBlur(row, 'value', row.value)}
          onKeyDown={e => handleKeyDown(e, row, 'value', row.value)}
          sx={{ width: '100px' }}
          error={!!formErrors[row.id]?.value}
          helperText={formErrors[row.id]?.value || ''}
        />
      ),
    },
    {
      headerName: 'Frequency',
      field: 'frequency',
      renderCell: row => (
        <FormControl disabled={!isEditMode} fullWidth variant="outlined">
          <StyledSelect
            value={row.frequency}
            IconComponent={KeyboardArrowDown}
            onChange={e => handleChange(row, 'frequency', e.target.value)}
            error={!!formErrors[row.id]?.frequency}
          >
            <MenuItem value="Single">Single</MenuItem>
            <MenuItem value="Ongoing">Ongoing</MenuItem>
          </StyledSelect>
        </FormControl>
      ),
    },
    {
      headerName: 'Effective From',
      field: 'effectiveFrom',
      renderCell: row => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDatePicker
            disabled={!isEditMode}
            label="Effective From"
            sx={{ width: '150px' }}
            value={dayjs(row.effectiveFrom)}
            onChange={newDate =>
              handleChange(
                row,
                'effectiveFrom',
                dayjs(newDate).format('YYYY-MM-DD')
              )
            }
            renderInput={params => (
              <TextField
                {...params}
                size="small"
                error={!!formErrors[row.id]?.effectiveFrom}
                helperText={formErrors[row.id]?.effectiveFrom || ''}
              />
            )}
          />
        </LocalizationProvider>
      ),
    },
    {
      headerName: 'Effective To',
      field: 'effectiveTo',
      renderCell: row => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDatePicker
            disabled={!isEditMode}
            label="Effective To"
            sx={{ width: '150px' }}
            value={dayjs(row.effectiveTo)}
            onChange={newDate =>
              handleChange(
                row,
                'effectiveTo',
                dayjs(newDate).format('YYYY-MM-DD')
              )
            }
            renderInput={params => (
              <TextField
                {...params}
                size="small"
                error={!!formErrors[row.id]?.effectiveTo}
                helperText={formErrors[row.id]?.effectiveTo || ''}
              />
            )}
          />
        </LocalizationProvider>
      ),
    },
    {
      headerName: 'Rules',
      field: 'rules',
      renderCell: row => (
        <TextField
          disabled={!isEditMode}
          value={row.rules}
          onChange={e => handleChange(row, 'rules', e.target.value)}
          onBlur={() => handleBlur(row, 'rules', row.rules)}
          onKeyDown={e => handleKeyDown(e, row, 'rules', row.rules)}
          sx={{ width: '150px' }}
          error={!!formErrors[row.id]?.rules}
          helperText={formErrors[row.id]?.rules || ''}
        />
      ),
    },
    {
      headerName: 'Notes',
      field: 'note',
      renderCell: row => (
        <TextField
          disabled={!isEditMode}
          value={row.note}
          onChange={e => handleChange(row, 'note', e.target.value)}
          onBlur={() => handleBlur(row, 'note', row.note)}
          onKeyDown={e => handleKeyDown(e, row, 'note', row.note)}
          sx={{ width: '150px' }}
          error={!!formErrors[row.id]?.note}
          helperText={formErrors[row.id]?.note || ''}
        />
      ),
    },
  ];
};

export default getAdjustmentsColumns;
