import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Box, Dialog, DialogActions, Button } from '@mui/material';
import styles from './payouts.module.scss';
import { GenericTable } from 'components/common/GenericTable';
import { getAllPayouts } from '_redux/services/categories.service';
import { getPayoutsColumns } from 'utils/Payouts';
import { PDFViewer } from '@react-pdf/renderer';
import PdfViewerModal from 'components/PdfViewerModal/PdfViewerModal';
import { authActions } from '_redux/slices/auth.slice';
import { useSelector } from 'react-redux';

const Payouts = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [advisor, setAdvisor] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const { user } = useSelector(authActions.selectors.getUser);

  const getCategoriesData = useCallback(async () => {
    const response = await getAllPayouts(page, rowsPerPage);
    const total = response.total;
    setTotalData(total);

    const lastPage = Math.ceil(total / rowsPerPage) - 1;
    if (page === lastPage) {
      const remainingItems = total % rowsPerPage || rowsPerPage;
      setData(response.data.slice(0, remainingItems));
    } else {
      setData(response.data);
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    getCategoriesData();
  }, [getCategoriesData]);

  const handleCloseModal = () => {
    setAdvisor(null);
    setModalOpen(false);
  };

  const handleView = row => {
    setAdvisor(row);
    setModalOpen(true);
    console.log('Viewing details for:', row);
  };
  const columns = getPayoutsColumns({ onView: handleView });

  return (
    <>
      <Box className={styles.dashboardContainer}>
        <Grid container>
          <Grid m={4} item xs={12}>
            <GenericTable
              title={'Payouts'}
              refreshData={getCategoriesData}
              setData={setData}
              pageConfig={{
                page,
                setPage,
                rowsPerPage,
                setRowsPerPage,
                totalRows: totalData,
              }}
              addNewProduct={false}
              onAddNewProduct={() => {}}
              columns={columns}
              data={data}
              isPayouts={true}
            />
          </Grid>
        </Grid>
      </Box>
      {advisor && (
        <Dialog fullScreen open={isModalOpen}>
          <Box sx={{ height: 1, display: 'flex', flexDirection: 'column' }}>
            <DialogActions
              sx={{
                p: 1.5,
              }}
            >
              <Button
                color="inherit"
                variant="contained"
                onClick={handleCloseModal}
              >
                Close
              </Button>
            </DialogActions>

            <Box sx={{ flexGrow: 1, height: 1, overflow: 'hidden' }}>
              <PDFViewer width="100%" height="100%">
                <PdfViewerModal user={user} data={advisor} />
              </PDFViewer>
            </Box>
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default Payouts;
