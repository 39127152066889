import { useState } from 'react';
import {
  MenuItem,
  Select,
  Checkbox,
  FormControl,
  styled,
  TextField,
} from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { KeyboardArrowDown } from '@mui/icons-material';

const StyledSelect = styled(Select)({
  '& .MuiSelect-select': {
    padding: '8px 32px 8px 12px',
    backgroundColor: '#f8f9fa',
    border: 'none',
    borderRadius: '4px',
    fontSize: '14px',
    color: '#495057',
    '&:focus': {
      backgroundColor: '#f8f9fa',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
});
export const getIntroducerColumns = ({
  selectedRows,
  handleRowSelection,
  handleValueChange,
  isEditMode,
  handleUpdateRow,
}) => {
  const [editingCell, setEditingCell] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [checkedAll, setCheckedAll] = useState(false);
  const [updatedRow, setUpdatedRow] = useState(null);

  if (editingCell) {
    console.log('');
  }

  const handleKeyDown = (event, row, field, value) => {
    if (event.key === 'Enter') {
      handleBlur(row, field, value);
    }
  };

  const handleChange = (row, field, value) => {
    if (value && formErrors[row.id]?.[field]) {
      setFormErrors(prev => ({
        ...prev,
        [row.id]: {
          ...prev[row.id],
          [field]: false,
        },
      }));
    }

    let error = false;
    if (!value && field !== 'comments') {
      error = 'This field is required';
    }

    if (field === 'email' && value && !/\S+@\S+\.\S+/.test(value)) {
      error = 'Please enter a valid email.';
    }

    setFormErrors(prev => ({
      ...prev,
      [row.id]: {
        ...prev[row.id],
        [field]: error || false,
      },
    }));

    if (isEditMode) {
      const newUpdatedRow =
        updatedRow?.id === row.id
          ? {
              ...updatedRow,
              valuesChanged: { ...updatedRow.valuesChanged, [field]: value },
            }
          : { id: row.id, valuesChanged: { [field]: value } };

      setUpdatedRow(newUpdatedRow);
      handleUpdateRow(newUpdatedRow);
    }

    handleValueChange(row.id, field, value);
  };

  const handleBlur = (row, field, value) => {
    if (!value) {
      setFormErrors(prev => ({
        ...prev,
        [row.id]: {
          ...prev[row.id],
          [field]: 'This field is required',
        },
      }));
    } else {
      setFormErrors(prev => ({
        ...prev,
        [row.id]: {
          ...prev[row.id],
          [field]: false,
        },
      }));
    }

    setEditingCell(null);
    handleValueChange(row.id, field, value);
  };

  const handleAllChecked = () => {
    setCheckedAll(!checkedAll);
  };

  return [
    {
      headerName: (
        <Checkbox
          size="small"
          checked={checkedAll}
          onChange={handleAllChecked}
        />
      ),
      field: 'selection',
      width: 50,
      renderCell: row => (
        <Checkbox
          size="small"
          checked={checkedAll || selectedRows.includes(row.id)}
          onChange={() => handleRowSelection(row.id)}
        />
      ),
    },
    { headerName: 'Role', field: 'role' },
    { headerName: 'ID', field: 'id' },
    {
      headerName: 'Name',
      field: 'name',
      renderCell: row => (
        <TextField
          disabled={!isEditMode}
          fullWidth
          value={row.name}
          onChange={e => handleChange(row, 'name', e.target.value)}
          onBlur={() => handleBlur(row, 'name', row.name)}
          onKeyDown={e => handleKeyDown(e, row, 'name', row.name)}
          autoFocus
          size="small"
          error={!!formErrors[row.id]?.name}
          helperText={formErrors[row.id]?.name ? 'This field is required' : ''}
          sx={{
            width: '200px',
            backgroundColor: formErrors[row.id]?.name
              ? '#FFCDD2'
              : 'transparent',
            color: formErrors[row.id]?.name ? 'red' : 'inherit',
            border: formErrors[row.id]?.name ? '1px solid red' : 'none',
          }}
        />
      ),
    },
    {
      headerName: 'Role Details',
      field: 'roleDetails',
      renderCell: row => (
        <FormControl disabled={!isEditMode} fullWidth variant="outlined">
          <StyledSelect
            value={row.roleDetails}
            fullWidth
            IconComponent={KeyboardArrowDown}
            onChange={e => handleChange(row, 'roleDetails', e.target.value)}
            error={!!formErrors[row.id]?.roleDetails}
          >
            <MenuItem value="Buyout">Buyout</MenuItem>
            <MenuItem value="Own Account">Own Account</MenuItem>
            <MenuItem value="EX-PBO">EX-BPO</MenuItem>
            <MenuItem value="EX">EX</MenuItem>
            <MenuItem value="N/A">N/A</MenuItem>
          </StyledSelect>
        </FormControl>
      ),
    },
    {
      headerName: 'Email',
      field: 'email',
      renderCell: row => (
        <TextField
          disabled={!isEditMode}
          value={row.email}
          onChange={e => handleChange(row, 'email', e.target.value)}
          onBlur={() => handleBlur(row, 'email', row.email)}
          onKeyDown={e => handleKeyDown(e, row, 'email', row.email)}
          autoFocus
          error={!!formErrors[row.id]?.email}
          helperText={
            formErrors[row.id]?.email ? 'Please enter a valid email' : ''
          }
          sx={{
            width: '250px',
            backgroundColor: formErrors[row.id]?.email
              ? '#FFCDD2'
              : 'transparent',
            color: formErrors[row.id]?.email ? 'red' : 'inherit',
            border: formErrors[row.id]?.email ? '1px solid red' : 'none',
          }}
        />
      ),
    },
    {
      headerName: 'Gross/Net',
      field: 'grossNet',
      renderCell: row => (
        <FormControl disabled={!isEditMode} fullWidth variant="outlined">
          <StyledSelect
            value={row.grossNet}
            IconComponent={KeyboardArrowDown}
            onChange={e => handleChange(row, 'grossNet', e.target.value)}
            error={!!formErrors[row.id]?.grossNet}
          >
            <MenuItem value="Gross">Gross</MenuItem>
            <MenuItem value="Net">Net</MenuItem>
          </StyledSelect>
        </FormControl>
      ),
    },
    {
      headerName: 'Effective From',
      field: 'effectiveFrom',
      renderCell: row => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDatePicker
            disabled={!isEditMode}
            label="Effective From*"
            sx={{ width: '150px' }}
            value={dayjs(row.effectiveFrom)}
            views={['year', 'month', 'day']}
            minDate={dayjs('2022-01-01')}
            maxDate={dayjs('2030-12-31')}
            onChange={newDate =>
              handleChange(
                row,
                'effectiveFrom',
                dayjs(newDate).format('YYYY-MM-DD')
              )
            }
            slots={{
              textField: props => (
                <TextField
                  {...props}
                  error={!!formErrors[row.id]?.effectiveFrom}
                  helperText={
                    formErrors[row.id]?.effectiveFrom
                      ? 'This field is required'
                      : ''
                  }
                />
              ),
            }}
          />
        </LocalizationProvider>
      ),
    },
    {
      headerName: 'Statements',
      field: 'statements',
      renderCell: row => (
        <FormControl disabled={!isEditMode} fullWidth variant="outlined">
          <StyledSelect
            value={row.statements}
            IconComponent={KeyboardArrowDown}
            onChange={e => handleChange(row, 'statements', e.target.value)}
            error={!!formErrors[row.id]?.statements}
          >
            <MenuItem value="Full Payment Due">Full Payment Due</MenuItem>
            <MenuItem value="Statement Not Required">
              Statement Not Required
            </MenuItem>
          </StyledSelect>
        </FormControl>
      ),
    },
    {
      headerName: 'Comments',
      field: 'comments',
      renderCell: row => (
        <TextField
          disabled={!isEditMode}
          value={row.comments}
          onChange={e => handleChange(row, 'comments', e.target.value)}
          onBlur={() => handleBlur(row, 'comments', row.comments)}
          onKeyDown={e => handleKeyDown(e, row, 'comments', row.comments)}
          autoFocus
          size="small"
          sx={{
            width: '200px',
            backgroundColor: 'transparent',
            color: 'inherit',
            border: 'none',
          }}
        />
      ),
    },
  ];
};
