import { Checkbox } from '@mui/material';

export const usersTableColumns = handleCheckboxChange => [
  { headerName: 'User ID', field: 'id' },
  { headerName: 'Name', field: 'name' },
  {
    headerName: 'Dashboard',
    field: 'dashboard',
    renderCell: (row, index) => (
      <Checkbox
        checked={!!row?.dashboard}
        onChange={e =>
          handleCheckboxChange(e?.target?.checked, row?.id, index, 'dashboard')
        }
      />
    ),
  },
  {
    headerName: 'Margin',
    field: 'margin',
    renderCell: (row, index) => (
      <Checkbox
        checked={!!row?.margin}
        onChange={e =>
          handleCheckboxChange(e?.target?.checked, row?.id, index, 'margin')
        }
      />
    ),
  },
  {
    headerName: 'Data Upload',
    field: 'dataUpload',
    renderCell: (row, index) => (
      <Checkbox
        checked={!!row?.dataUpload}
        onChange={e =>
          handleCheckboxChange(e?.target?.checked, row?.id, index, 'dataUpload')
        }
      />
    ),
  },
  {
    headerName: 'Error Log',
    field: 'errorLog',
    renderCell: (row, index) => (
      <Checkbox
        checked={!!row?.errorLog}
        onChange={e =>
          handleCheckboxChange(e?.target?.checked, row?.id, index, 'errorLog')
        }
      />
    ),
  },
  {
    headerName: 'Commission Splits',
    field: 'commissionSplits',
    renderCell: (row, index) => (
      <Checkbox
        checked={!!row?.commissionSplits}
        onChange={e =>
          handleCheckboxChange(
            e?.target?.checked,
            row?.id,
            index,
            'commissionSplits'
          )
        }
      />
    ),
  },
  {
    headerName: 'Advisor Payout',
    field: 'advisorPayout',
    renderCell: (row, index) => (
      <Checkbox
        checked={!!row?.advisorPayout}
        onChange={e =>
          handleCheckboxChange(
            e?.target?.checked,
            row?.id,
            index,
            'advisorPayout'
          )
        }
      />
    ),
  },
  {
    headerName: 'Adjustments',
    field: 'advisorReport',
    renderCell: (row, index) => (
      <Checkbox
        checked={!!row?.advisorReport}
        onChange={e =>
          handleCheckboxChange(
            e?.target?.checked,
            row?.id,
            index,
            'advisorReport'
          )
        }
      />
    ),
  },
  {
    headerName: 'Mail Box',
    field: 'mailBox',
    renderCell: (row, index) => (
      <Checkbox
        checked={!!row?.mailBox}
        onChange={e =>
          handleCheckboxChange(e?.target?.checked, row?.id, index, 'mailBox')
        }
      />
    ),
  },
  {
    headerName: 'Support',
    field: 'support',
    renderCell: (row, index) => (
      <Checkbox
        checked={!!row?.support}
        onChange={e =>
          handleCheckboxChange(e?.target?.checked, row?.id, index, 'support')
        }
      />
    ),
  },
];
