import React, { useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import styles from './statementSetup.module.scss';
import Introducer from 'components/Introducer/Introducer';
import AdvisorStatement from 'components/AdvisorStatement/AdvisorStatement';
import { getAllCategories } from '_redux/services/categories.service';

const StatementSetUp = () => {
  const [tab, setTab] = useState(0);
  const [categories, setCategories] = useState();

  const categoiresData = async () => {
    const response = await getAllCategories();
    setCategories(response.data);
  };
  useEffect(() => {
    categoiresData();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const tabs = [{ label: 'Introducers' }, { label: 'Advisors' }];
  const renderContent = () => {
    switch (tab) {
      case 0:
        return (
          <Box>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  marginRight: '3.5%',
                  width: '100%',
                  overflowX: 'hidden',
                }}
              >
                <Introducer
                  handleTabChange={handleTabChange}
                  tab={tab}
                  tabs={tabs}
                />
              </Grid>
            </Grid>
          </Box>
        );
      case 1:
        return (
          <Box>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  marginRight: '3.5%',
                  width: '100%',
                  overflowX: 'hidden',
                }}
              >
                <AdvisorStatement
                  advisorFields={categories}
                  handleTabChange={handleTabChange}
                  tab={tab}
                  tabs={tabs}
                />
              </Grid>
            </Grid>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box className={styles.dashboardContainer}>
      <Box>
        <Grid container>
          <Grid m={4} item xs={12}>
            {renderContent()}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default StatementSetUp;
