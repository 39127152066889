import React, { useState, useEffect, useCallback } from 'react';
import { GenericTable } from 'components/common/GenericTable';
import { getIntroducerColumns } from 'utils/Introducer';
import AddNewIntroducer from 'components/AddNewIntroducer/AddNewIntroducer';
import AddNewAdvisor from 'components/AddNewAdvisor/AddNewAdvisor';
import { Toaster, toast } from 'react-hot-toast';
import { getAllIntroducers } from '_redux/services/introducer.service';
import { editIntroducer } from '_redux/services/introducer.service';

const Introducer = ({ handleTabChange, tab, tabs }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState();
  const [isIntroducerModalOpen, setisIntroducerModalOpen] = useState(false);
  const [isAdvisorModalOpen, setisAdvisorModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [updatedRow, setUpdatedRow] = useState({});

  const getIntroducersData = useCallback(async () => {
    try {
      const response = await getAllIntroducers(page, rowsPerPage);
      const total = response.total;
      setTotalData(total);

      setData(prevData => {
        const lastPage = Math.ceil(total / rowsPerPage) - 1;
        if (page === lastPage) {
          const remainingItems = total % rowsPerPage || rowsPerPage;
          return response.data.slice(0, remainingItems);
        }
        return response.data;
      });
    } catch (error) {
      console.error('Error fetching advisors:', error);
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    getIntroducersData();
  }, [getIntroducersData]);

  const handleRowSelection = id => {
    setSelectedRows(prev =>
      prev.includes(id) ? prev.filter(rowId => rowId !== id) : [...prev, id]
    );
  };

  const handleValueChange = (id, field, value) => {
    setData(prevData =>
      prevData.map(row => (row.id === id ? { ...row, [field]: value } : row))
    );
  };

  const handleAdvisorClick = () => {
    setisAdvisorModalOpen(true);
  };

  const handleIntroducerClick = () => {
    setisIntroducerModalOpen(true);
  };

  const handleAdisorModalClose = () => {
    setisAdvisorModalOpen(false);
  };

  const handleModalClose = () => {
    setisIntroducerModalOpen(false);
  };

  const handleEditToggle = () => {
    setIsEditMode(prev => !prev);
  };
  const handelCancelEdit = () => {
    setIsEditMode(false);
    getIntroducersData();
  };

  const handleSaveChanges = async () => {
    try {
      const newRow = await editIntroducer(
        updatedRow.id,
        updatedRow.valuesChanged
      );

      if (newRow.name) {
        toast.success('Row updated successfully!');
        handleEditToggle();
        getIntroducersData();
      }
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const handleUpdateRow = row => {
    setUpdatedRow(row);
  };

  const columns = getIntroducerColumns({
    selectedRows,
    handleRowSelection,
    handleValueChange,
    isEditMode,
    handleUpdateRow,
  });

  return (
    <>
      <Toaster />
      <GenericTable
        title="Set-up"
        refreshData={getIntroducersData}
        addNewIntroducer={true}
        addNewAdvisor={true}
        handleEditToggle={handleEditToggle}
        handleCancelEdit={handelCancelEdit}
        handleSaveChanges={handleSaveChanges}
        isEditMode={isEditMode}
        showEdit={true}
        columns={columns}
        data={data}
        setData={setData}
        isIntroducer={true}
        pageConfig={{
          page,
          setPage,
          rowsPerPage,
          setRowsPerPage,
          totalRows: totalData,
        }}
        handleTabChange={handleTabChange}
        onAddIntroducer={handleIntroducerClick}
        onAddAdvisor={handleAdvisorClick}
        tab={tab}
        isTabs={true}
        tabs={tabs}
      />
      {isIntroducerModalOpen && (
        <AddNewIntroducer
          refreshData={getIntroducersData}
          onClose={handleModalClose}
          open={isIntroducerModalOpen}
          handleClose={handleModalClose}
        />
      )}
      {isAdvisorModalOpen && (
        <AddNewAdvisor
          onClose={handleAdisorModalClose}
          open={isAdvisorModalOpen}
          handleClose={handleAdisorModalClose}
          refreshData={getIntroducersData}
        />
      )}
    </>
  );
};

export default Introducer;
