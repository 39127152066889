import { useState } from 'react';
import {
  MenuItem,
  Select,
  Checkbox,
  FormControl,
  styled,
  TextField,
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

const StyledSelect = styled(Select)({
  '& .MuiSelect-select': {
    padding: '8px 32px 8px 12px',
    backgroundColor: '#f8f9fa',
    border: 'none',
    borderRadius: '4px',
    fontSize: '14px',
    color: '#495057',
    '&:focus': {
      backgroundColor: '#f8f9fa',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
});
export const getAdjustmentTypesColumns = ({
  selectedRows,
  handleRowSelection,
  handleValueChange,
  isEditMode,
  handleUpdateRow,
}) => {
  const [editingCell, setEditingCell] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [checkedAll, setCheckedAll] = useState(false);
  const [updatedRow, setUpdatedRow] = useState(null);

  if (editingCell) {
    console.log('');
  }

  const handleKeyDown = (event, row, field, value) => {
    if (event.key === 'Enter') {
      handleBlur(row, field, value);
    }
  };

  const handleChange = (row, field, value) => {
    if (value && formErrors[row.id]?.[field]) {
      setFormErrors(prev => ({
        ...prev,
        [row.id]: {
          ...prev[row.id],
          [field]: false,
        },
      }));
    }

    let error = false;
    if (!value && field !== 'comments') {
      error = 'This field is required';
    }

    if (field === 'email' && value && !/\S+@\S+\.\S+/.test(value)) {
      error = 'Please enter a valid email.';
    }

    setFormErrors(prev => ({
      ...prev,
      [row.id]: {
        ...prev[row.id],
        [field]: error || false,
      },
    }));

    if (isEditMode) {
      const newUpdatedRow =
        updatedRow?.id === row.id
          ? {
              ...updatedRow,
              valuesChanged: { ...updatedRow.valuesChanged, [field]: value },
            }
          : { id: row.id, valuesChanged: { [field]: value } };

      setUpdatedRow(newUpdatedRow);
      handleUpdateRow(newUpdatedRow);
    }

    handleValueChange(row.id, field, value);
  };

  const handleBlur = (row, field, value) => {
    if (!value && field !== 'comments') {
      setFormErrors(prev => ({
        ...prev,
        [row.id]: {
          ...prev[row.id],
          [field]: 'This field is required',
        },
      }));
    } else {
      setFormErrors(prev => ({
        ...prev,
        [row.id]: {
          ...prev[row.id],
          [field]: false,
        },
      }));
    }

    setEditingCell(null);
    handleValueChange(row.id, field, value);
  };

  const handleAllChecked = () => {
    setCheckedAll(!checkedAll);
  };

  return [
    {
      headerName: (
        <Checkbox
          size="small"
          checked={checkedAll}
          onChange={handleAllChecked}
        />
      ),
      field: 'selection',
      width: 50,
      renderCell: row => (
        <Checkbox
          size="small"
          checked={checkedAll || selectedRows.includes(row.id)}
          onChange={() => handleRowSelection(row.id)}
        />
      ),
    },
    {
      headerName: 'Title',
      field: 'title',
      renderCell: row => (
        <TextField
          disabled={!isEditMode}
          value={row.title}
          onChange={e => handleChange(row, 'title', e.target.value)}
          onBlur={() => handleBlur(row, 'title', row.title)}
          onKeyDown={e => handleKeyDown(e, row, 'title', row.title)}
          autoFocus
          size="small"
          error={!!formErrors[row.id]?.title}
          helperText={formErrors[row.id]?.title || ''}
          sx={{
            width: '200px',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: formErrors[row.id]?.title
                  ? 'red'
                  : 'rgba(0, 0, 0, 0.23)',
              },
              '&:hover fieldset': {
                borderColor: formErrors[row.id]?.title
                  ? 'red'
                  : 'rgba(0, 0, 0, 0.23)',
              },
              '&.Mui-focused fieldset': {
                borderColor: formErrors[row.id]?.title ? 'red' : '#1976d2',
              },
            },
            '& .MuiFormHelperText-root': {
              color: 'red',
            },
          }}
        />
      ),
    },
    {
      headerName: 'Type',
      field: 'type',
      renderCell: row => (
        <FormControl disabled={!isEditMode} fullWidth variant="outlined">
          <StyledSelect
            value={row.type}
            IconComponent={KeyboardArrowDown}
            onChange={e => handleChange(row, 'type', e.target.value)}
          >
            <MenuItem value="Deduction">Deduction</MenuItem>
            <MenuItem value="Addition">Addition</MenuItem>
          </StyledSelect>
        </FormControl>
      ),
    },

    {
      headerName: 'Description',
      field: 'description',
      renderCell: row => (
        <TextField
          disabled={!isEditMode}
          value={row.description}
          onChange={e => handleChange(row, 'description', e.target.value)}
          onBlur={() => handleBlur(row, 'description', row.name)}
          onKeyDown={e => handleKeyDown(e, row, 'description', row.name)}
          autoFocus
          size="small"
          sx={{
            backgroundColor: 'transparent',
            color: 'inherit',
            border: 'none',
          }}
        />
      ),
    },
  ];
};
