import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  IconButton,
  TextField,
  FormControl,
  styled,
  Select,
  MenuItem,
} from '@mui/material';
import styles from './setUpAdjustments.module.scss';
import { Close, KeyboardArrowDown } from '@mui/icons-material';
import ModalContainer from 'components/common/ModalContainer/ModalContainer';
import { Toaster, toast } from 'react-hot-toast';
import { addAdjustmentTypes } from '_redux/services/adjustmentTypes.service';

const StyledSelect = styled(Select)({
  '& .MuiSelect-select': {
    padding: '15px 32px 15px 15px',
    backgroundColor: '#f8f9fa',
    border: 'none',
    borderRadius: '4px',
    fontSize: '14px',
    color: '#495057',
    '&:focus': {
      backgroundColor: '#f8f9fa',
    },
  },
});

const SetUpAdjustments = ({ onClose, open, refreshData }) => {
  const [formValues, setFormValues] = useState({
    title: '',
    type: '',
    description: '',
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const tempErrors = {};
    if (!formValues.title.trim()) tempErrors.title = 'Title is required';
    if (!formValues.type.length) tempErrors.type = ' Type is required';

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSetUp = async () => {
    if (!validateForm()) {
      toast.error('Please fill in all required fields');
      return;
    }

    try {
      const response = await addAdjustmentTypes(formValues);

      if (response.id) {
        toast.success('Adjustment Type added successfully!');
        onClose();
        refreshData();
      }
    } catch (error) {
      toast.error('An error occurred while adding Adjustment Type');
    }
  };

  return (
    <>
      <Toaster />
      <ModalContainer open={open}>
        <Box className={styles.earlyPaymentContainer}>
          <Box className={styles.heading}>
            <Typography variant="h6" className={styles.title}>
              Set-up Adjustments
            </Typography>
            <IconButton onClick={onClose} className={styles.closeButton}>
              <Close />
            </IconButton>
          </Box>

          <Box className={styles.formContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box sx={{ marginBottom: 2 }}>
                  <Typography variant="body1" component="label">
                    Title <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    name="title"
                    value={formValues.title}
                    onChange={e => {
                      setErrors(prevErrors => ({ ...prevErrors, title: '' }));
                      setFormValues({
                        ...formValues,
                        title: e.target.value,
                      });
                    }}
                    variant="outlined"
                    error={!!errors.title}
                    helperText={errors.title}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ marginBottom: 2 }}>
                  <Typography variant="body1" component="label">
                    Type <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <FormControl
                    error={!!errors.type}
                    fullWidth
                    variant="outlined"
                  >
                    <StyledSelect
                      value={formValues.type}
                      IconComponent={KeyboardArrowDown}
                      onChange={e => {
                        setErrors(prevErrors => ({ ...prevErrors, type: '' }));
                        setFormValues({ ...formValues, type: e.target.value });
                      }}
                    >
                      <MenuItem value="Deduction">Deduction</MenuItem>
                      <MenuItem value="Addition">Addition</MenuItem>
                    </StyledSelect>
                  </FormControl>
                  <Typography
                    sx={{
                      color: 'rgb(205,41,41)',
                      fontSize: '12px',
                      ml: '17px',
                      mt: '3px',
                    }}
                  >
                    {errors.type ? errors.type : ''}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ marginBottom: 2 }}>
                  <Typography variant="body1" component="label">
                    Description
                  </Typography>
                  <TextField
                    fullWidth
                    name="Description"
                    value={formValues.description}
                    onChange={e =>
                      setFormValues({
                        ...formValues,
                        description: e.target.value,
                      })
                    }
                    variant="outlined"
                  />
                </Box>
              </Grid>
            </Grid>

            <Box className={styles.actionButtons}>
              <Button
                variant="outlined"
                sx={{
                  textTransform: 'none',
                  borderRadius: '8px',
                  borderColor: '#003366',
                  color: '#003366',
                  '&:hover': {
                    borderColor: '#002244',
                    color: 'white',
                    backgroundColor: '#003366',
                  },
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="primary"
                sx={{
                  textTransform: 'none',
                  borderRadius: '8px',
                  border: '1px solid #003366',
                  backgroundColor: '#003366',
                  color: 'white',
                  padding: '8px 16px',
                  marginRight: '15px',
                  '&:hover': {
                    backgroundColor: '#002244',
                    color: 'white',
                  },
                }}
                onClick={handleSetUp}
              >
                Setup
              </Button>
            </Box>
          </Box>
        </Box>
      </ModalContainer>
    </>
  );
};

export default SetUpAdjustments;
