import React, { useEffect, useState } from 'react';
import { GenericTable } from 'components/common/GenericTable';
import ModalContainer from 'components/common/ModalContainer/ModalContainer';
import { reportColumns } from 'utils/reports';
import { useDispatch, useSelector } from 'react-redux';
import { payoutActions } from '_redux/slices/payout.slice';
import ComposeMail from 'components/ComposeMail/ComposeMail';
import PdfViewerModal from 'components/PdfViewerModal/PdfViewerModal';
import { pdf, PDFViewer } from '@react-pdf/renderer';
import { Dialog, DialogActions, Button, Box } from '@mui/material';
import dayjs from 'dayjs';
import { FETCH_PAYOUTS1 } from '_redux/types/payout.types';
import { authActions } from '_redux/slices/auth.slice';
import { saveAs } from 'file-saver';
import ComposeMailMultiple from 'components/ComposeMail/ComposeMailMultiple';

const Tracking = ({ handleTabChange, tab }) => {
  const dispatch = useDispatch();
  const payouts = useSelector(payoutActions.selectors.getPayouts);
  const totalPayouts = useSelector(payoutActions.selectors.getTotalPayouts);
  const filteredPayouts = payouts.filter(
    payout =>
      payout?.advisor?.advisorDetails &&
      payout?.advisor?.advisorDetails?.length > 0
  );

  const { user } = useSelector(authActions.selectors.getUser);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRows, setSelectedRows] = useState([]);

  const [period, setPeriod] = useState('weekly');

  const [date, setDate] = useState(dayjs());

  useEffect(() => {
    const month = date.month() + 1;
    const year = date.year();
    dispatch({
      type: FETCH_PAYOUTS1,
      payload: {
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        month,
        year,
        // period
      },
    });
  }, [dispatch, page, rowsPerPage, date]);

  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpen1, setModalOpen1] = useState(false);
  const [selectedAdvisor, setSelectedAdvisor] = useState(null);
  const [pdfAttachment, setPdfAttachment] = useState(null);

  const [pdfUrl, setPdfUrl] = useState(null);

  const handleViewAdvisor = advisorData => {
    setSelectedAdvisor(advisorData);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalOpen1(false);
    setSelectedAdvisor(null);
    setPdfAttachment(null);
    if (pdfUrl) {
      URL.revokeObjectURL(pdfUrl);
      setPdfUrl(null);
    }
  };

  const handleDownload = async advisorData => {
    setSelectedAdvisor(advisorData);

    const pdfDoc = (
      <PdfViewerModal advisorData={advisorData} user={user} date={date} />
    );
    const pdfBlob = await pdf(pdfDoc).toBlob();

    saveAs(pdfBlob, `${advisorData?.advisorName}_Report.pdf`);
  };

  const handleShare = async advisorData => {
    setSelectedAdvisor(advisorData);

    const pdfDoc = (
      <PdfViewerModal advisorData={advisorData} user={user} date={date} />
    );
    const pdfBlob = await pdf(pdfDoc).toBlob();

    const pdfFile = new File(
      [pdfBlob],
      `${advisorData?.advisorName}_Report.pdf`,
      {
        type: 'application/pdf',
      }
    );

    setPdfAttachment(pdfFile);
    setModalOpen1(true);
  };

  const handleShareMultiple = async rows => {
    const pdfDocs = rows?.map((row, index) => (
      <PdfViewerModal advisorData={row} user={user} date={date} key={index} />
    ));

    const pdfBlobs = [];
    for (let i = 0; i < pdfDocs.length; i++) {
      const pdfBlob = await pdf(pdfDocs[i]).toBlob();
      pdfBlobs.push(pdfBlob);
    }
    const pdfFiles = pdfBlobs?.map(
      (pdfBlob, index) =>
        new File([pdfBlob], `${rows?.[index]?.advisorName}_Report.pdf`, {
          type: 'application/pdf',
        })
    );

    setPdfAttachment(pdfFiles); // Set the array of PDF files
    setModalOpen1(true);
  };

  return (
    <>
      <GenericTable
        title="Statments"
        data={filteredPayouts}
        columns={reportColumns(handleViewAdvisor, handleDownload, handleShare)}
        pageConfig={{
          page,
          setPage,
          rowsPerPage,
          setRowsPerPage,
          totalRows: totalPayouts,
        }}
        handleTabChange={handleTabChange}
        tab={tab}
        isTabs={false}
        isDownload={false}
        period={period}
        setPeriod={setPeriod}
        isPeriod={false}
        isDate={true}
        date={setDate}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        isReport={true}
        onShareReport={handleShareMultiple}
      />
      {selectedAdvisor && (
        <Dialog fullScreen open={isModalOpen}>
          <Box sx={{ height: 1, display: 'flex', flexDirection: 'column' }}>
            <DialogActions
              sx={{
                p: 1.5,
              }}
            >
              <Button
                color="inherit"
                variant="contained"
                onClick={handleCloseModal}
              >
                Close
              </Button>
            </DialogActions>

            <Box sx={{ flexGrow: 1, height: 1, overflow: 'hidden' }}>
              <PDFViewer width="100%" height="100%">
                <PdfViewerModal
                  advisorData={selectedAdvisor}
                  user={user}
                  date={date}
                />
              </PDFViewer>
            </Box>
          </Box>
        </Dialog>
      )}

      <ModalContainer open={isModalOpen1} onClose={handleCloseModal}>
        {selectedAdvisor && (
          <ComposeMail onClose={handleCloseModal} file={pdfAttachment} />
        )}
        {!!selectedRows?.length && (
          <ComposeMailMultiple
            onClose={handleCloseModal}
            file={pdfAttachment}
          />
        )}
      </ModalContainer>
    </>
  );
};

export default Tracking;
