import {
  Box,
  TextField,
  Checkbox,
  TableCell,
  FormControl,
  MenuItem,
  styled,
  Select,
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useCallback, useEffect, useState } from 'react';
import { getAdvisorsList } from '_redux/services/advisor.service';
import { getIntroducersList } from '_redux/services/introducer.service';

export const COLLABORATION_FIELDS = [
  {
    headerName: 'Collaborator1',
    field: '',
    renderCell: () => `Collaborator 1`,
  },
  { headerName: 'ID', field: 'collaboratorId1', editable: true },
  { headerName: 'Name', field: 'collaboratorName1', editable: true },
  { headerName: 'Frequency', field: 'collaboratorFrequency1', editable: true },
  {
    headerName: 'Percentage',
    field: 'collaboratorPercentage1',
    editable: true,
  },
  { headerName: 'Amount', field: 'collaboratorAmount1', editable: true },
  {
    headerName: 'Collaborator2',
    field: '',
    renderCell: () => `Collaborator 2`,
  },
  { headerName: 'ID', field: 'collaboratorId2', editable: true },
  { headerName: 'Name', field: 'collaboratorName2', editable: true },
  { headerName: 'Frequency', field: 'collaboratorFrequency2', editable: true },
  {
    headerName: 'Percentage',
    field: 'collaboratorPercentage2',
    editable: true,
  },
  { headerName: 'Amount', field: 'collaboratorAmount2', editable: true },
  {
    headerName: 'Final Main Advisor Commission',
    field: 'mainAdvisorFinalCommission',
    editable: true,
  },
];

export const getCollaboratorColumns = ({
  selectedRows,
  handleRowSelection,
  handleValueChange,
  isEditMode,
  handleUpdateRow,
}) => {
  const [editingCell, setEditingCell] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [checkedAll, setCheckedAll] = useState(false);
  const [updatedRow, setUpdatedRow] = useState();
  const [names, setNames] = useState([]);

  const getCollaboratorsNames = useCallback(async () => {
    const advisors = await getAdvisorsList();
    const introducers = await getIntroducersList();
    setNames([...advisors, ...introducers]);
  }, [isEditMode]);

  useEffect(() => {
    getCollaboratorsNames();
  }, [getCollaboratorsNames]);

  if (editingCell) {
    console.log('');
  }

  const handleKeyDown = (event, row, field, value) => {
    if (event.key === 'Enter') {
      handleBlur(row, field, value);
    }
  };

  const handleChange = (row, field, value) => {
    if (value && formErrors[row.id]?.[field]) {
      setFormErrors(prev => ({
        ...prev,
        [row.id]: {
          ...prev[row.id],
          [field]: false,
        },
      }));
    }

    let error = false;
    if (!value && field !== 'comments') {
      error = 'This field is required';
    }

    if (field === 'email' && value && !/\S+@\S+\.\S+/.test(value)) {
      error = 'Please enter a valid email.';
    }

    setFormErrors(prev => ({
      ...prev,
      [row.id]: {
        ...prev[row.id],
        [field]: error || false,
      },
    }));

    let newValuesChanged = {};

    if (field.startsWith('collaborator')) {
      const collaboratorNumber = field.includes('1') ? '1' : '2';
      if (field.includes('Name')) {
        const selectedName = names?.find(item => item.name === value);
        newValuesChanged = {
          [`collaboratorName${collaboratorNumber}`]: value,
          [`collaboratorId${collaboratorNumber}`]: selectedName?.id,
        };
      } else {
        newValuesChanged = { [field]: value };
      }
    } else {
      newValuesChanged = { [field]: value };
    }

    if (isEditMode) {
      const newUpdatedRow = {
        id: row.id,
        valuesChanged: {
          ...(updatedRow?.valuesChanged || {}),
          ...newValuesChanged,
        },
      };

      setUpdatedRow(newUpdatedRow);
      handleUpdateRow(newUpdatedRow);
    }

    Object.entries(newValuesChanged).forEach(([key, val]) => {
      handleValueChange(row.id, key, val);
    });
  };
  const handleBlur = (row, field, value) => {
    if (!value) {
      setFormErrors(prev => ({
        ...prev,
        [row.id]: {
          ...prev[row.id],
          [field]: 'This field is required',
        },
      }));
    } else {
      setFormErrors(prev => ({
        ...prev,
        [row.id]: {
          ...prev[row.id],
          [field]: false,
        },
      }));
    }

    setEditingCell(null);
    handleValueChange(row.id, field, value);
  };

  const handleAllChecked = () => {
    setCheckedAll(!checkedAll);
  };

  return [
    {
      headerName: (
        <Checkbox
          size="small"
          checked={checkedAll}
          onChange={handleAllChecked}
        />
      ),
      field: 'selection',
      width: 50,
      renderCell: row => (
        <Checkbox
          sx={{
            backgroundColor: formErrors[row.id]?.length
              ? '#FFCDD2'
              : 'transparent',
            color: formErrors[row.id]?.length ? 'red' : 'inherit',
            border: formErrors[row.id]?.length ? '1px solid red' : 'none',
          }}
          size="small"
          checked={checkedAll || selectedRows.includes(row.id)}
          onChange={() => handleRowSelection(row.id)}
        />
      ),
    },
    { headerName: 'Transaction ID', field: 'transactionId' },
    { headerName: 'Client Name', field: 'clientName' },
    { headerName: 'Advisor ID', field: 'advisorId', editable: true },
    {
      headerName: 'Main Advisor Name',
      field: 'mainAdvisorName',
      editable: true,
      // renderCell: (row) => `${row?.splitPercentage?.toFixed(2)}%`,
    },
    {
      headerName: 'Commission Amount Gross',
      field: 'commissionAmountGross',
      renderCell: row =>
        `${row?.commissionAmountGross === 0 ? '--' : `£${row?.commissionAmountGross?.toFixed(2)}`}`,
    },
    {
      headerName: 'Commission Amount Net',
      field: 'commissionAmountNet',
      renderCell: row =>
        `${row?.commissionAmountNet === 0 ? '--' : `£${row?.commissionAmountNet?.toFixed(2)}`}`,
    },
    {
      headerName: 'Collaboration Type',
      field: 'collaborationType',
      editable: true,
      renderCell: row => (
        <TableCell
          sx={{
            backgroundColor: formErrors[row.id]?.collaborationType
              ? '#FFCDD2'
              : 'transparent',
            color: formErrors[row.id]?.collaborationType ? 'red' : 'inherit',
            border: formErrors[row.id]?.collaborationType
              ? '1px solid red'
              : 'none',
          }}
        >
          <FormControl disabled={!isEditMode} fullWidth variant="outlined">
            <StyledSelect
              value={row.collaborationType}
              IconComponent={KeyboardArrowDown}
              onChange={e =>
                handleChange(row, 'collaborationType', e.target.value)
              }
              error={!!formErrors[row.id]?.collaborationType}
            >
              <MenuItem value="Advisor">Advisor</MenuItem>
              <MenuItem value="Introducer">Introducer</MenuItem>
              <MenuItem value="Advisor & Introducer">
                Advisor & Introducer
              </MenuItem>
            </StyledSelect>
          </FormControl>
        </TableCell>
      ),
    },
    ...COLLABORATION_FIELDS.map(({ headerName, field, renderCell }) => ({
      headerName:
        headerName === 'Collaborator1' || headerName === 'Collaborator2'
          ? ''
          : headerName,
      field:
        headerName === 'Collaborator1' || headerName === 'Collaborator2'
          ? ''
          : field,
      renderCell: row =>
        headerName === 'Collaborator1' || headerName === 'Collaborator2' ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
          >
            <Box
              style={{
                backgroundColor: '#0A2756',
                color: 'white',
                width: '100%',
                minHeight: '140%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '14px',
                padding: '8px',
                textAlign: 'center',
              }}
            >
              {headerName}
            </Box>
          </Box>
        ) : headerName === 'Percentage' ? (
          <TextField
            disabled={!isEditMode}
            value={row[field]}
            type="number"
            inputProps={{ min: 0 }}
            onChange={e =>
              handleChange(
                row,
                field,
                Math.max(0, Number.parseInt(e.target.value))
              )
            }
            onBlur={() => handleBlur(row, field, row.email)}
            onKeyDown={e => handleKeyDown(e, row, field, row.email)}
            autoFocus
            size="small"
            sx={{
              width: '200px',
            }}
          />
        ) : headerName === 'Frequency' ? (
          <TableCell
            sx={{
              backgroundColor: formErrors[row.id]?.[field]
                ? '#FFCDD2'
                : 'transparent',
              color: formErrors[row.id]?.[field] ? 'red' : 'inherit',
              border: formErrors[row.id]?.[field] ? '1px solid red' : 'none',
            }}
          >
            <FormControl disabled={!isEditMode} fullWidth variant="outlined">
              <StyledSelect
                value={row[field]}
                IconComponent={KeyboardArrowDown}
                onChange={e => handleChange(row, field, e.target.value)}
                error={!!formErrors[row.id]?.[field]}
              >
                <MenuItem value="Single">Single</MenuItem>
                <MenuItem value="Ongoing">Ongoing</MenuItem>
              </StyledSelect>
            </FormControl>
          </TableCell>
        ) : headerName === 'Name' ? (
          <TableCell
            sx={{
              backgroundColor: formErrors[row.id]?.[field]
                ? '#FFCDD2'
                : 'transparent',
              color: formErrors[row.id]?.[field] ? 'red' : 'inherit',
              border: formErrors[row.id]?.[field] ? '1px solid red' : 'none',
            }}
          >
            <FormControl disabled={!isEditMode} fullWidth variant="outlined">
              <StyledSelect
                value={row[field]}
                IconComponent={KeyboardArrowDown}
                onChange={e => {
                  const selectedAdvisor = names?.find(
                    item => item.name === e.target.value
                  );
                  const collaboratorNumber = field.includes('1') ? '1' : '2';
                  handleChange(
                    row,
                    `collaboratorName${collaboratorNumber}`,
                    selectedAdvisor?.name
                  );
                }}
                error={!!formErrors[row.id]?.[field]}
              >
                {names?.map(item => (
                  <MenuItem key={item.id} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          </TableCell>
        ) : headerName === 'Amount' ? (
          `${row?.[field] === 0 ? '--' : `£${row?.[field]?.toFixed(2)}`}`
        ) : headerName === 'Final Main Advisor Commission' ? (
          `${row?.[field] === 0 ? '--' : `£${row?.[field]?.toFixed(2)}`}`
        ) : (
          row?.[field] || ''
        ),
    })),
  ];
};

const StyledSelect = styled(Select)({
  '& .MuiSelect-select': {
    padding: '8px 32px 8px 12px',
    backgroundColor: '#f8f9fa',
    border: 'none',
    borderRadius: '4px',
    fontSize: '14px',
    color: '#495057',
    '&:focus': {
      backgroundColor: '#f8f9fa',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
});
