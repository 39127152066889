import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  IconButton,
  TextField,
} from '@mui/material';
import styles from './addNewProduct.module.scss';
import { Close } from '@mui/icons-material';
import ModalContainer from 'components/common/ModalContainer/ModalContainer';
import { Toaster, toast } from 'react-hot-toast';
import CustomChip from './CustomChip';
import {
  createProductCategory,
  editProductCategory,
} from '_redux/services/categories.service';

const AddNewProduct = ({
  selectedRow,
  isEditMode,
  isViewMode,
  onClose,
  open,
  handleEditToggle,
  refreshData,
}) => {
  const [productData, setProductData] = useState({
    productCategory: '',
    productType: [],
    incomeType: [],
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (selectedRow) {
      setProductData(selectedRow);
    }
  }, [selectedRow]);

  const validateForm = () => {
    const tempErrors = {};
    if (!productData.productCategory.trim())
      tempErrors.productCategory = 'Product Category is required';
    if (productData.productType.length === 0)
      tempErrors.productType = 'At least one Product Type is required';
    if (productData.incomeType.length === 0)
      tempErrors.incomeType = 'At least one Income Type is required';

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validateForm()) {
      toast.error('Please fill in all required fields');
      return;
    }

    try {
      const response = isEditMode
        ? await editProductCategory(productData.id, {
            incomeType: productData.incomeType,
            productCategory: productData.productCategory,
            productType: productData.productType,
          })
        : await createProductCategory(productData);

      if (response.id) {
        toast.success(
          isEditMode
            ? 'Product updated successfully!'
            : 'Product added successfully!'
        );
        onClose();
        refreshData();
      }
    } catch (error) {
      toast.error('An error occurred while saving the product');
    }
  };

  return (
    <>
      <Toaster />
      <ModalContainer open={open}>
        <Box className={styles.earlyPaymentContainer}>
          <Box className={styles.heading}>
            <Typography variant="h6" className={styles.title}>
              {isEditMode ? 'Edit Product Type' : 'Add Product Type'}
            </Typography>
            <IconButton onClick={onClose} className={styles.closeButton}>
              <Close />
            </IconButton>
          </Box>

          <Box className={styles.formContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box sx={{ marginBottom: 2 }}>
                  <Typography variant="body1" component="label">
                    Product Category <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField
                    disabled={isViewMode}
                    fullWidth
                    name="productCategory"
                    value={productData.productCategory}
                    onChange={e =>
                      setProductData({
                        ...productData,
                        productCategory: e.target.value,
                      })
                    }
                    variant="outlined"
                    error={!!errors.productCategory}
                    helperText={errors.productCategory}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ marginBottom: 2 }}>
                  <Typography variant="body1" component="label">
                    Product Type <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <CustomChip
                    disabled={isViewMode}
                    label="Product Type"
                    value={productData.productType}
                    onChange={newChips =>
                      setProductData({ ...productData, productType: newChips })
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ marginBottom: 2 }}>
                  <Typography variant="body1" component="label">
                    Income Type <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <CustomChip
                    disabled={isViewMode}
                    label="Income Type"
                    value={productData.incomeType}
                    onChange={newChips =>
                      setProductData({ ...productData, incomeType: newChips })
                    }
                  />
                </Box>
              </Grid>
            </Grid>

            <Box className={styles.actionButtons}>
              {isViewMode ? (
                <Button
                  variant="contained"
                  type="primary"
                  sx={{
                    textTransform: 'none',
                    borderRadius: '8px',
                    border: '1px solid #003366',
                    backgroundColor: '#003366',
                    color: 'white',
                    padding: '8px 16px',
                    marginRight: '15px',
                    '&:hover': {
                      backgroundColor: '#002244',
                      color: 'white',
                    },
                  }}
                  onClick={handleEditToggle}
                >
                  Edit
                </Button>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: 'none',
                      borderRadius: '8px',
                      borderColor: '#003366',
                      color: '#003366',
                      '&:hover': {
                        borderColor: '#002244',
                        color: 'white',
                        backgroundColor: '#003366',
                      },
                    }}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    type="primary"
                    sx={{
                      textTransform: 'none',
                      borderRadius: '8px',
                      border: '1px solid #003366',
                      backgroundColor: '#003366',
                      color: 'white',
                      padding: '8px 16px',
                      marginRight: '15px',
                      '&:hover': {
                        backgroundColor: '#002244',
                        color: 'white',
                      },
                    }}
                    onClick={handleSave}
                  >
                    {isEditMode ? 'Save' : 'Add'}
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </ModalContainer>
    </>
  );
};

export default AddNewProduct;
