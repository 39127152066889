import React, { useState } from 'react';
import { Chip, Paper, InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const CustomChip = ({ label, value = [], onChange, required, disabled }) => {
  const [inputValue, setInputValue] = useState('');
  const [editIndex, setEditIndex] = useState(-1);
  const [editValue, setEditValue] = useState('');

  const handleInputChange = event => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = event => {
    if (event.key === 'Enter' && inputValue.trim()) {
      event.preventDefault();
      const newChips = [...value, inputValue.trim()];
      onChange(newChips);
      setInputValue('');
    }
  };

  const handleDelete = chipIndex => {
    const newChips = value.filter((_, index) => index !== chipIndex);
    onChange(newChips);
    setEditIndex(-1);
  };

  const handleEdit = index => {
    setEditIndex(index);
    setEditValue(value[index]);
  };

  const handleEditInputChange = event => {
    setEditValue(event.target.value);
  };

  const handleEditKeyDown = (event, index) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const newChips = [...value];
      newChips[index] = editValue.trim();
      onChange(newChips);
      setEditIndex(-1);
    }
  };

  return (
    <Paper
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 1,
        m: 0,
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: 1,
        minHeight: 50,
        alignItems: 'center',
      }}
      component="ul"
    >
      {value?.map((chip, index) => (
        <ListItem key={index}>
          {editIndex === index ? (
            <InputBase
              disabled={disabled}
              value={editValue}
              onChange={handleEditInputChange}
              onKeyDown={e => handleEditKeyDown(e, index)}
              autoFocus
              onBlur={() => setEditIndex(-1)}
              sx={{ ml: 1 }}
            />
          ) : (
            <Chip
              disabled={disabled}
              label={chip}
              onDelete={() => handleDelete(index)}
              onClick={() => handleEdit(index)}
              deleteIcon={<CloseIcon />}
              icon={<EditIcon />}
            />
          )}
        </ListItem>
      ))}
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={label}
        required={required}
        disabled={disabled}
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
      />
    </Paper>
  );
};

export default CustomChip;
