import {
  DasboardIcon,
  DataUploadIcon,
  SupportIcon,
  MailIcon,
  ErrorIcon,
  SplitsIcon,
  EpmPayoutIcon,
  ReportIcon,
  SettingsIcon,
  RefundIcon,
  MarginIcon,
  UserManagement,
  DownArrow,
} from 'assets/svgs';
import styles from '../components/Sidebar/SideBar/sidebar.module.scss';

const ROUTES = [
  {
    path: '/',
    name: 'Dashboard',
    icon: <DasboardIcon />,
    roles: ['manager'],
    key: 'dashboard',
  },
  {
    path: '/margin',
    name: 'Margin',
    icon: <MarginIcon />,
    roles: ['manager'],
    key: 'margin',
  },
  {
    path: '/upload',
    name: 'Data Upload',
    icon: <DataUploadIcon />,
    roles: ['manager'],
    key: 'dataUpload',
  },
  {
    path: '/error-logs',
    name: 'Error Log',
    icon: <ErrorIcon />,
    roles: ['manager'],
    key: 'errorLog',
  },
  {
    path: '/collaborations',
    name: 'Collaborations',
    icon: <SplitsIcon />,
    roles: ['manager'],
    key: 'commissionSplits',
  },
  {
    path: '/adjustments',
    name: 'Adjustments',
    icon: <EpmPayoutIcon />,
    roles: ['manager'],
    key: 'advisorPayout',
  },
  {
    path: '/statements',
    name: (
      <span className={styles.menuItemWithArrow}>
        Statements
        <DownArrow className={styles.downArrow} />
      </span>
    ),
    icon: <ReportIcon />,
    roles: ['manager'],
    key: 'advisorReport',
    subRoutes: [
      {
        path: '/statements/setup',
        name: 'Set-up',
        icon: <SettingsIcon />,
        roles: ['manager'],
      },
      {
        path: '/statements/payouts',
        name: 'Payouts',
        icon: <EpmPayoutIcon />,
        roles: ['manager'],
      },
      {
        path: '/statements/categories',
        name: 'Categories Setup',
        icon: <ReportIcon />,
        roles: ['manager'],
      },
    ],
  },
  {
    path: '/mail-box',
    name: 'Mail Box',
    icon: <MailIcon />,
    roles: ['manager'],
    key: 'mailBox',
  },
  {
    path: '/profile-settings',
    name: 'Profile Settings',
    icon: <SettingsIcon />,
    roles: ['manager'],
  },
  {
    path: '/support',
    name: 'Support',
    icon: <SupportIcon />,
    roles: ['manager'],
    key: 'support',
  },
  {
    path: '/payment-request',
    name: 'Payment Request',
    icon: <ReportIcon />,
    roles: ['admin'],
  },
  {
    path: '/refund-request',
    name: 'Refund Request',
    icon: <RefundIcon />,
    roles: ['admin'],
  },
  {
    path: '/mail-history',
    name: 'Mail Box',
    icon: <MailIcon />,
    roles: ['admin'],
  },
  {
    path: '/users-management',
    name: 'Users Management',
    icon: <UserManagement />,
    roles: ['admin'],
  },
];

export default ROUTES;
