import React, { useState } from 'react';
import { Grid, Box } from '@mui/material';
import styles from './employeePayout.module.scss';
import AdvisorAdjustments from 'components/AdvisorAdjustments/AdvisorAdjustments';
import AdjustmentTypes from 'components/AdjustmentTypes/AdjustmentTypes';

const EmployeePayout = () => {
  const [tab, setTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const tabs = [
    { label: 'Advisor Adjustments' },
    { label: 'Adjustment Types' },
  ];

  const renderContent = () => {
    switch (tab) {
      case 0:
        return (
          <AdvisorAdjustments
            handleTabChange={handleTabChange}
            tab={tab}
            tabs={tabs}
          />
        );
      case 1:
        return (
          <AdjustmentTypes
            handleTabChange={handleTabChange}
            tab={tab}
            tabs={tabs}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box className={styles.dashboardContainer}>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {renderContent()}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default EmployeePayout;
